import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Stack, Typography, TextField } from '@mui/material';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

MonthlyOrderGraph.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function MonthlyOrderGraph({ title, getNewData, subheader, chartLabels, chartData, ...other }) {
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '45%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'date' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} orders`;
          }
          return y;
        },
      },
      // x: {
      //   formatter: (x) => '',
      // },
    },
  });
  const [startDate, setStartDate] = useState(moment().subtract(5, 'month').startOf('month').format('YYYY-MM'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM'));
  const [options, setOptions] = useState({ startDate, endDate });

  const handleStartDateChange = (e) => {
    const temp = { ...options, startDate: e.target.value };
    setOptions(temp);
    setStartDate(e.target.value);
    getNewData(temp);
  };
  const handleEndDateChange = (e) => {
    const temp = { ...options, endDate: e.target.value };
    setEndDate(e.target.value);
    setOptions(temp);
    getNewData(temp);
  };

  return (
    <Card sx={{ minHeight: '507px' }}>
      <Stack direction={'column'} alignItems="center" padding={1} sx={{ width: '100%' }}>
        <Typography marginBottom={1.5} variant="h5">
          {title}
        </Typography>
        <Stack
          direction={'row'}
          paddingX={1}
          spacing={1}
          alignItems="center"
          sx={{ width: '100%' }}
          justifyContent="flex-end"
        >
          <TextField
            sx={{
              '& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '22px',
                fontSize: '13px',
                fontWeight: 500, 
                width: '120px',
                padding: '5.5px 4px',
              },
              '& .css-gpl0nr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '13px',
                top: 2,
              },
            }}
            label="Start Month"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            id="margin-none"
            value={startDate}
            type="month"
            onChange={handleStartDateChange}
          />

          <TextField
            sx={{
              '& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '22px',
                fontSize: '13px',
                fontWeight: 500,
                width: '120px',
                padding: '5.5px 4px',
              },
              '& .css-gpl0nr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '13px',
                top: 2,
              },
            }}
            label="End Month"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            type="month"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Stack>
      </Stack>

      <Box sx={{ px: 3, pb: 1, pt: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
