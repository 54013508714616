import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Box } from '@mui/system';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/UserFilter';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import Popup from '../components/Popup';

export default function User() {
  const [userData, setUserData] = useState();
  const [pages, setPages] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getUsers(pages);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);

    getUsers(newPage);
  };

  const getUsers = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setUserCount(res.data.data.maxRecords);
          setUserData(res.data.data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.name.trim()) {
      temp.name = filter.name.trim();
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    setOptions(temp);
    setPages(0);
    getUsers(temp);
  };
  useEffect(() => {
    getUsers(options);
  }, []);
  return (
    <>
      <Page title="User">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  Users
                </Typography>
                {/* <Button variant="contained">New User</Button> */}
              </Stack>

              <Card>
                <Stack padding={1}>
                  <Filter applyFilters={applyFilters} />
                </Stack>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <UserListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>User ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Member since</TableCell>
                          <TableCell>User status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#fff0ed' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>

                            <TableCell style={{ cursor: 'pointer', width: '100px' }}>
                              <Stack direction="column" spacing={0.5} width="100px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('User id copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#cb4d4d' }}
                                    />
                                    <Typography sx={{ color: '#cb4d4d' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>

                            <TableCell>{item.fullname.toUpperCase()}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{Moment(item.createdAt).format('MMMM DD, YYYY')}</TableCell>

                            <TableCell>
                              <FormControl sx={{ minWidth: 120 }}>
                                <Select value={!item.isBlocked} onChange={(e) => handleStatusChange(e, item, id)}>
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Blocked</MenuItem>
                                </Select>
                                {/* {id === 0 && <Popup item={item}  /> }
                                 */}
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Link to={`/dashboard/user/${item._id}`}>
                                  <Icon sx={{ color: 'gray' }}>
                                    <RemoveRedEyeOutlinedIcon />
                                  </Icon>
                                </Link>
                                {/* <Icon sx={{ color: 'red' }}>
                                <DeleteIcon />
                              </Icon> */}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  showFirstButton
                  showLastButton
                  count={userCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
