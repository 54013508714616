import React, { useState } from 'react';
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Page from '../components/Page';
import OrderSuccesfulList from '../components/OrderSuccessList';
import OrderFailedList from '../components/OrderFailedList';
import OrderInProgressList from '../components/OrderInProgress';

export default function Orders() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Orders">
      <Container maxWidth="xl">
        <Stack direction="row" spacing={2}>
          <Typography paddingBottom={1} variant="h5">
            Orders
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="SUCCESS" value="1" />
                  <Tab label="IN PROGRESS" value="2" />
                  <Tab label="FAILED" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <OrderSuccesfulList />
              </TabPanel>
              <TabPanel value="2">
                <OrderInProgressList />
              </TabPanel>
              <TabPanel value="3">
                <OrderFailedList />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
