import React from 'react';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, Menu, MenuItem } from '@mui/material';
import Iconify from './Iconify';

export const OrderFilter = ({ applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box style={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton onClick={handleClick}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Formik
            initialValues={{
              invoiceNumber: '',
              userId: '',
              courierId: '',
              isReplacement: '',
              deliveryStatus: '',
              refundStatus: '',
              startDate: '',
              endDate: '',
              paymentType: '',
            }}
            onSubmit={async (values) => {
              applyFilters(values);
            }}
          >
            {({ values, handleChange }) => (
              <Form style={{ padding: '10px' }}>
                <Stack direction="column">
                  <Stack direction="row" spacing={2}>
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="userId"
                      name="userId"
                      fullWidth
                      label="User ID"
                      value={values.userId}
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="invoiceNumber"
                      name="invoiceNumber"
                      label="Invoice Number"
                      value={values.invoiceNumber}
                      onChange={handleChange}
                      fullWidth
                    />
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="courierId"
                      name="courierId"
                      fullWidth
                      label="AWB code"
                      value={values.courierId}
                      onChange={handleChange}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2} style={{ paddingBottom: '10px' }}>
                    <TextField
                      select
                      label="Order Type"
                      fullWidth
                      name="isReplacement"
                      value={values.isReplacement}
                      onChange={handleChange}
                    >
                      <MenuItem value="false">NORMAL</MenuItem>
                      <MenuItem value="true">REPLACEMENT</MenuItem>
                    </TextField>{' '}
                    <TextField
                      select
                      label="Delivery Status"
                      fullWidth
                      name="deliveryStatus"
                      value={values.deliveryStatus}
                      onChange={handleChange}
                    >
                      <MenuItem value="PENDING">PENDING</MenuItem>
                      <MenuItem value="PACKAGE IN PROGRESS">PACKAGE IN PROGRESS</MenuItem>
                      <MenuItem value="DELIVERED">DELIVERED</MenuItem>
                      <MenuItem value="CANCELLED">CANCELLED</MenuItem>
                      <MenuItem value="RETURNED">RETURNED</MenuItem>
                    </TextField>
                  </Stack>

                  <Stack direction="row" spacing={2} style={{ paddingBottom: '10px' }}>
                    <TextField
                      select
                      label="Refund Status"
                      fullWidth
                      name="refundStatus"
                      value={values.refundStatus}
                      onChange={handleChange}
                    >
                      <MenuItem value="PENDING">PENDING</MenuItem>
                      <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                      <MenuItem value="NA">NA</MenuItem>
                    </TextField>
                    <TextField
                      select
                      label="Payment Type"
                      fullWidth
                      name="paymentType"
                      value={values.paymentType}
                      onChange={handleChange}
                    >
                      <MenuItem value="COD">COD</MenuItem>
                      <MenuItem value="ONLINE">ONLINE</MenuItem>
                    </TextField>
                  </Stack>

                  <Stack direction={'row'} spacing={2}>
                    <TextField
                      fullWidth
                      type="date"
                      name="startDate"
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={values.startDate}
                      onChange={handleChange}
                    />

                    <p style={{ paddingTop: '15px' }}>to</p>

                    <TextField
                      fullWidth
                      type="date"
                      name="endDate"
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      value={values.endDate}
                      onChange={handleChange}
                    />
                  </Stack>

                  <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                    Filter
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Menu>
      </Box>
    </div>
  );
};
