import React, { useState } from 'react';
import { Button, Card, Container, Stack, Typography, Box, Tabs, Tab, CircularProgress } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Categories from '../components/Categories';
import Page from '../components/Page';
import ProductsList from '../components/ProductsList';

export default function Eshop() {
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = (message = '') => {
    if (message) {
      toast(message);
    }
  };
  return (
    <Page title="E-shop">
      <Container maxWidth="xl">
        <Card sx={{ maxHeight: '100%' }}>
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Products" value="1" />
                  <Tab label="Categories" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">{<ProductsList handleClose={handleClose} />}</TabPanel>
              <TabPanel value="2">{<Categories handleClose={handleClose} />}</TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
