import moment from 'moment';
import React, { useState } from 'react';
import {
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const DeliveryPopup = (props) => {
  const [deliveryDate, setDeliveryDate] = useState(moment().format('YYYY-MM-DD'));
  const status = { type: 'delivery', value: props.status };
  const open = true;
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/order/admin/status`, { id: props.item?._id, status, deliveryDate })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        props.handleClose(false);
      });
  };

  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>Are you sure you want to change the delivery status of this order?</DialogContent>
        {props.status === 'DELIVERED' && (
          <Stack paddingX={3} spacing={2} direction="row" justifyContent={'center'} alignItems="center">
            <Typography>Delivered Date</Typography>
            <TextField
              type={'date'}
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.target.value)}
              size="small"
            />
          </Stack>
        )}
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeliveryPopup;
