import React from 'react';
import AddProducts from '../components/AddProducts';

const ProductPage = () => {
  return (
    <div>
      <AddProducts />
    </div>
  );
};

export default ProductPage;
 