import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';

export default function PaymentMethodSettings() {
  const [editMode, setEditMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cod, setCod] = React.useState({ name: '', isEnabled: false });
  const [onlinePayment, setOnlinePayment] = React.useState({ name: '', isEnabled: false });
  const [disableButton, setDisableButton] = React.useState(false);

  const getSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
      console.log(res);
      setIsLoading(false);
      setCod(res.data.data.cod);
      setOnlinePayment(res.data.data.onlinePayment);
      setEditMode(false);
      setDisableButton(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateEnabled = async (paymentType, newValue) => {
    setIsLoading(true);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/settings/payment-method/status`, {
        paymentMethod: paymentType,
        isEnabled: newValue,
        name: paymentType === 'cod' ? cod.name : onlinePayment.name,
      });
      getSettings();
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  const updateName = async () => {
    setIsLoading(true);
    const settings = {
      cod, 
      onlinePayment,
    };
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/settings/payment-method/update`, { settings });
      getSettings();
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Card sx={{ backgroundColor: '#FCEEED', height: '250px' }}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <CircularProgress />
        </div>
      ) : (
        <Stack sx={{ display: 'flex', direction: 'column', width: '100%', alignItems: 'center' }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            sx={{ width: '100%', backgroundColor: '#FF9393', padding: 1 }}
          >
            <Typography variant="h6">Payment Method</Typography>
          </Stack>
          {!editMode && (
            <Stack
              sx={{ width: '100%', padding: 2, marginTop: '20px' }}
              spacing={2}
              alignItems="center"
              justifyContent={'center'}
            >
              <Grid container paddingX={1} spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ borderRadius: 1 }}>
                    <Typography>{cod.name}</Typography>
                    <Checkbox checked={cod.isEnabled} onChange={(e) => updateEnabled('cod', e.target.checked)} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ borderRadius: 1 }}>
                    <Typography>{onlinePayment.name}</Typography>
                    <Checkbox
                      checked={onlinePayment.isEnabled}
                      onChange={(e) => updateEnabled('onlinePayment', e.target.checked)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '22px' }}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        bgcolor: '#FF9393',
                        color: '#000',
                        boxShadow: 'none',
                        '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                      }}
                      onClick={() => setEditMode(true)}
                    >
                      Edit Name
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          )}
          {editMode && (
            <Stack
              sx={{ width: '100%', padding: 2, marginTop: '20px' }}
              spacing={2}
              alignItems="center"
              justifyContent={'center'}
            >
              <Grid container paddingX={1} spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <TextField
                      label="COD Name"
                      variant="outlined"
                      size="small"
                      value={cod.name}
                      onChange={(e) => setCod({ ...cod, name: e.target.value })}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <TextField
                      label="Online Payment Name"
                      variant="outlined"
                      size="small"
                      value={onlinePayment.name}
                      onChange={(e) => setOnlinePayment({ ...onlinePayment, name: e.target.value })}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" spacing={2} alignItems="center" marginTop={2.5}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        bgcolor: '#FF9393',
                        color: '#000',
                        boxShadow: 'none',
                        '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                      }}
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={disableButton}
                      sx={{
                        bgcolor: '#FF9393',
                        color: '#000',
                        boxShadow: 'none',
                        '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                      }}
                      onClick={() => {
                        setDisableButton(true);
                        updateName();
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Stack>
      )}
    </Card>
  );
}
