import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Warning } from '@mui/icons-material';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Page from './Page';
import Scrollbar from './Scrollbar';
import CategoryAddForm from './CategoryForm';
import CategoryEditForm from './CategoryEditForm';
import DeletePopup from './DeletePopup';
import { ProductsFilter } from './ProductsFilter';

export default function Products(props) {
  const [products, setProducts] = useState([]);
  const [pages, setPages] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isPopup, setIsPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedIndexToEdit, setSelectedIndexToEdit] = useState();
  const [selectedIndexToDelete, setSelectedIndexToDelete] = useState();
  const [lowStock, setLowStock] = useState(false);
  const size = 10;
  const [options, setOptions] = useState({ page: pages, size });
  const navigate = useNavigate();
  const capitalizeFirst = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  //   const handleStatusChange = async (event, item, index) => {
  //     console.log('item', item);
  //     const values = { name: item.name, categoryId: item._id, isPopular: 'true' };
  //     if (item.isPopular) {
  //       values.isPopular = 'false';
  //     }
  //     await updatePopular(values);
  //     getCategories(pages);
  //   };

  //   const updatePopular = async (values) => {
  //     const upadteStatus = await axios.put(`${process.env.REACT_APP_API_URL}/category/edit`, values);
  //     console.log(upadteStatus);
  //     toast(upadteStatus.data.message);
  //   };

  //   const handleDeletePopup = (event, item, index) => {
  //     setSelectedIndexToDelete(index);
  //   };
  //   const handleEditPopup = (event, item, index) => {
  //     setSelectedIndexToEdit(index);
  //   };
  //   const handleAddPopup = () => {
  //     setIsPopup(true);
  //   };

  //   const handleClose = (refresh = false, message = '') => {
  //     setIsPopup(false);
  //     setDeletePopup(false);
  //     setEditPopup(false);
  //     setSelectedIndexToEdit();
  //     setSelectedIndexToDelete();
  //     if (refresh) {
  //       getCategories(pages);
  //     }

  //     if (message) {
  //       props.handleClose(message);
  //     }
  //   };

  const handleProductStatusChange = async (event, item) => {
    console.log('item', item);
    try {
      const res = await axios
        .put(`${process.env.REACT_APP_API_URL}/product/status`, {
          id: item._id,
          isBlocked: event.target.value,
        })
        .then((res) => {
          if (!res.data.isError) {
            getProducts();
            toast.success('Status updated');
          }
        });
    } catch (error) {
      console.log(error);
    }
    // setSelectedIndex(index);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getProducts(temp);
  };

  const getProducts = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/product/all`, {
        params: options,
      });
      console.log('res', res);
      setProductCount(res.data.data.maxRecords);
      setProducts(res.data.data.products);
      setLowStock(res.data.data.lowStock);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const applyFilters = (filter) => {
    console.log('bfilter', filter);
    const temp = { page: 0, size };
    if (filter.name.length) {
      temp.name = filter.name;
    }
    if (filter.skuId.length) {
      temp.skuId = filter.skuId;
    }
    if (filter.brandName.length) {
      temp.brandName = filter.brandName;
    }
    if (filter.categoryName.length) {
      temp.categoryName = filter.categoryName;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.stock) {
      temp.stock = filter.stock;
    }
    setPages(0);
    getProducts(temp);
    setOptions(temp);
  };

  const handleLowStock = (e) => {
    if (e.target.checked) {
      const temp = { ...options, stock: 'LOW_STOCK' };
      setOptions(temp);
      getProducts(temp);
    } else {
      const temp = { ...options };
      delete temp.stock;
      setOptions(temp);
      getProducts(temp);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <Page>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between" paddingBottom={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddIcon />}
                    onClick={() => navigate('/dashboard/add/products')}
                  >
                    Add Products
                  </Button>
                  {lowStock && (
                    <Button
                      variant="outlined"
                      sx={{ pointerEvents: 'none' }}
                      size="small"
                      endIcon={<Warning />}
                      color="error"
                    >
                      Low Stock
                    </Button>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                  {lowStock && (
                    <FormControlLabel
                      control={<Switch onChange={handleLowStock} checked={options.stock === 'LOW_STOCK'} />}
                      label="Low Stock"
                    />
                  )}
                  <ProductsFilter applyFilters={applyFilters} />
                </Stack>
              </Stack>
              {/* {isPopup && <CategoryAddForm handleClose={handleClose} />} */}
              <Card>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <UserListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Product Image</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Brand Name</TableCell>
                          <TableCell>Category Name</TableCell>
                          {/* <TableCell>Featured</TableCell> */}
                          <TableCell>Status</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#fff0ed' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  width: 100,
                                  height: 100,
                                  overflow: 'hidden',
                                }}
                              >
                                <img alt="category" src={item.images[0]} />
                              </Box>
                            </TableCell>
                            <TableCell width={'250px'}>{capitalizeFirst(item.name)}</TableCell>
                            <TableCell>{item.brandName ? capitalizeFirst(item.brandName) : 'NA'}</TableCell>

                            <TableCell>{capitalizeFirst(item.categoryName)}</TableCell>
                            {/* <TableCell>
                                <FormControl>
                                  <Select
                                    size="small"
                                    value={item.isFeatured}
                                    // onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell> */}
                            <TableCell>
                              <FormControl>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleProductStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">Blocked</MenuItem>
                                  <MenuItem value="false">Active</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                                  <IconButton
                                    aria-label="view"
                                    size="small"
                                    onClick={() => navigate(`/dashboard/eshop/product/${item._id}`)}
                                  >
                                    <RemoveRedEyeOutlinedIcon fontSize="small" />
                                  </IconButton>

                                  {/* <Button
                                      variant="contained"
                                      size="small"
                                      endIcon={<EditIcon />}
                                      //   onClick={(e) => handleEditPopup(e, item, id)}
                                    >
                                      Edit
                                    </Button> */}
                                </Stack>
                              </Box>
                              {/* {selectedIndexToEdit === id && (
                                  <CategoryEditForm selectedItem={item} handleClose={handleClose} />
                                )}
                                {selectedIndexToDelete === id && (
                                  <DeletePopup selectedItem={item} handleClose={handleClose} />
                                )} */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={productCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  page={pages}
                />
              </Card>
            </Box>
          </>
        )}
      </Page>
    </>
  );
}
