import moment from 'moment';
import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { Grid, Container, useMediaQuery, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppCurrentVisits, AppWebsiteVisits, MemberPieChart } from '../sections/@dashboard/app';
import MonthlyOrderGraph from '../sections/@dashboard/app/MonthlyOrderGraph';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const matches = useMediaQuery('(max-width:1419px)');
  const [isLoading1, setIsLoading1] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [loader3, setLoader3] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading10, setIsLoading10] = useState(true);
  const [orderCounts, setOrderCounts] = useState([]);
  const [orderAmounts, setOrderAmounts] = useState([]);
  const [userMonthlyCount, setUserMonthlyCount] = useState({});
  const [orderGraphData, setOrderGraphData] = useState();

  const monthlyOrders = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/monthly-order-count`, {
        params: options,
      });
      console.log('monthly orders', res.data.data);
      const temp = res.data.data.graphData;
      setOrderGraphData(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserMonthlyCount = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/monthly-new-user-count`, {
        params: options,
      });
      console.log('monthly user', res);
      const temp = res.data.data;
      setUserMonthlyCount(temp);
      setIsLoading10(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderCounts = async (options) => {
    try {
      setLoader1(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/order-products-counts`, {
        params: options,
      });
      console.log('res', res);
      const temp = res.data.data;
      setLoader1(false);
      setOrderCounts(temp);
      if (temp.length) {
        setIsLoading1(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderAmount = async (options) => {
    try {
      setLoader3(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/effective-amount`, { params: options });
      console.log('order amounts', res);
      const temp = res.data.data;
      setLoader3(false);
      setOrderAmounts(temp);
      if (temp.length) {
        setIsLoading3(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserMonthlyCount({
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(5, 'month').format('YYYY-MM-DD'),
    });
    monthlyOrders({
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(5, 'month').format('YYYY-MM-DD'),
    });
    getOrderCounts();
    getOrderAmount();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={8}>
            <AppWebsiteVisits
              title="NEW MEMBERS"
              getNewData={getUserMonthlyCount}
              // graphloader={loader1}
              chartLabels={userMonthlyCount?.chartLabels}
              chartData={[
                {
                  name: 'New Members',
                  type: 'bar',
                  fill: 'solid',
                  data: userMonthlyCount?.userCount,
                  color:"#cb4d4d"
                },
              ]}
            />
          </Grid>

          {!isLoading3 && (
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="SALE SUMMARY"
                chartData={orderAmounts}
                getNewData={getOrderAmount}
                graphloader={loader3}
                chartColors={['#5AD236', '#365ad2', '#D2365A']}
              />
            </Grid>
          )}

          <Grid item xs={12} md={12} lg={8}>
            <MonthlyOrderGraph
              title="MONTHLY SALES"
              getNewData={monthlyOrders}
              // graphloader={loader1}
              chartLabels={orderGraphData?.chartLabels}
              chartData={[
                {
                  name: 'Normal orders',
                  type: 'line',
                  fill: 'solid',
                  data: orderGraphData?.normalOrderCount,
                  color: '#5AD236',
                },
                {
                  name: 'Replacement orders',
                  type: 'line',
                  fill: 'solid',
                  data: orderGraphData?.replacementOrderCount,
                  color: '#365ad2',
                },
                {
                  name: 'Return orders',
                  type: 'line',
                  fill: 'solid',
                  data: orderGraphData?.returnOrderCount,
                  color: '#D2365A',
                },
              ]}
            />
          </Grid>
          {!isLoading1 && (
            <Grid item xs={12} md={6} lg={4}>
              <MemberPieChart
                title="ORDERS"
                chartData={orderCounts}
                getNewData={getOrderCounts}
                graphloader={loader1}
                chartColors={['#5AD236', '#365ad2', '#D2365A']}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
