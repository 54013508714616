import {
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Stack, Typography
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';

const RefundPopup = (props) => {
  console.log(props);
  const status = { type: 'refund', value: props.status };
  const [refundAmount, setRefundAmount] = useState(props.item.grandTotal);
  console.log(props.item)
  console.log(status);
  const open = true;
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/order/admin/status`, { id: props.item?._id, status, refundAmount })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(
          false,
          err && err.response && err.response.data && err.response.data.message
            ? err.data.response.message
            : err.message
        );
      });
  };

  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>Are you sure you want to change the payment status of this order?

        {props.status === 'SUCCESS' && (
          <Stack paddingX={3}  mt={3} spacing={2} direction="row" justifyContent={'center'} alignItems="center">
            <Typography>Refund Amount</Typography>
            <TextField
              type={'number'}
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
              size="small"
            />
          </Stack>
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RefundPopup;
