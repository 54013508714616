import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond';

import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// Import FilePond styles

import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import CloseIcon from '@mui/icons-material/Close';
//  React draft editor
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CategoryModel from './CategoryModel';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

const productSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  brandName: Yup.string().required('Brand name is required'),
  shortName: Yup.string().required('Short name is required'),
  replacementPeriod: Yup.number().typeError("Replacement Period must be a valid number"),
  returnPeriod: Yup.number().typeError("Return Period must be a valid number"),
  isFeatured: Yup.string(),
  isPopular: Yup.string(),
  newArrival: Yup.string(),
  isBlocked: Yup.string(),

  addVariant: Yup.array().of(
    Yup.object().shape({
      price: Yup.number()
        .typeError('Price must be a number')
        .required('Price is required'),
      sellingPrice: Yup.number()
        .typeError('Selling Price must be a number')
        .required('Selling Price is required'),
      stock: Yup.number()
        .typeError('Stock must be a number')
        .required('Stock is required'),
      skuId: Yup.string().required('SKU ID is required'),
      offer: Yup.string().required('Offer Percentage is required'),
    })
  ),
});

const EditProduct = ({ product }) => {
  const [files, setFiles] = useState([]);
  const [sizeFile, setSizeFile] = useState([]);
  const [addVariant, setAddVariant] = useState([]);
  const [categoryValue, setCategoryValue] = useState(product?.categoryId);
  const [editorState, setEditorState] = useState();
  const [sizes, setSizes] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (product) {
      setAddVariant(product.variants);
      setEditorState(
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(`${product?.description}`)))
      );
    }
  }, [product]);

  const handleAddVariant = () => {
    addVariant.push({ price: '', size: '', sellingPrice: '', offer: '', stock: '', skuId: '' });
    setAddVariant([...addVariant]);
  };
  const handleCloseVariant = () => {
    const temp = addVariant;
    temp.pop();
    setAddVariant([...addVariant]);
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // console.log('editor', editorData);
  };



  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: productSchema,
    initialValues: {
      name: product.name,
      shortName: product.shortName,
      brandName: product.brandName,
      replacementPeriod: product.replacementPeriod,
      returnPeriod: product.returnPeriod,
      isBlocked: `${product.isBlocked}`,
      isFeatured: `${product.isFeatured}`,
      isPopular: `${product.isPopular}`,
      newArrival: `${product.newArrival}`,
      addVariant: product.variants || [{ price: '', size: '', sellingPrice: '', offer: '', stock: '', skuId: '' }],
    },
    onSubmit: async () => {
      const editorData = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
      try {
        const data = new FormData();
        data.append('categoryId', product.categoryId ? product.categoryId : categoryValue);
        data.append('description', editorData);
        Object.entries(values).forEach(([key, value], index) => {
          data.append(key, value);
        });

        data.append('variants', JSON.stringify(addVariant));

        data.append('productId', product._id);
        files.forEach((item) => {
          data.append('images', item.file);
        });
        sizeFile.forEach((item) => {
          data.append('sizeChartFile', item.file);
        });

        // data.append('files', files);
        // data.append('values', values);

        // console.log('data', data);
        // console.log('values', { ...values, varients: addVariant, images: data, category: `${categoryValue}` });
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/product`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          toast.success('Product Updated');
          navigate('/dashboard/eshop');
          console.log('edit', res.data);
        } else {
          console.log(res.data);
        }
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong. Try again');
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  console.log("addVariant", addVariant)

  const getSizes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
      console.log(res);
      const temp = res.data.data.sizes;
      setSizes(temp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSizes();
  }, [values]);

  const discountPercentage = (sellingPrice, mrp) => {
    return Math.round((1 - sellingPrice / mrp) * 100);
  };

  const onSellingPriceChange = (e, index) => {
    const sellingPrice = e.target.value;
    const price = parseFloat(addVariant[index].price);
    const percentage = discountPercentage(sellingPrice, price);
    addVariant[index].sellingPrice = sellingPrice;
    addVariant[index].offer = `${percentage}% off`;
    setAddVariant([...addVariant]);
  }
  const onPriceChange = (e, index) => {
    const mrp = e.target.value;
    const sellingPrice = parseFloat(addVariant[index].sellingPrice);
    const percentage = discountPercentage(sellingPrice, mrp);
    addVariant[index].price = mrp;
    addVariant[index].offer = `${percentage}% off`;
    setAddVariant([...addVariant]);
  }

  return (
    <div>
      <Container maxWidth="xl">
        <Typography variant="h4" paddingBottom={1}>
          Edit Product
        </Typography>
        {/* FormikProvider  */}
        <FormikProvider value={formik} >
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px', paddingTop: '15px' }}>
              <Stack direction={'row'} spacing={5} padding={2}>
                <TextField
                  label="Title"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack direction={'row'} spacing={5} padding={2}>
                <TextField
                  label="Short Name"
                  {...getFieldProps('shortName')}
                  error={Boolean(touched.shortName && errors.shortName)}
                  helperText={touched.shortName && errors.shortName}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Brand Name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps('brandName')}
                  error={Boolean(touched.brandName && errors.brandName)}
                  helperText={touched.brandName && errors.brandName}
                />
              </Stack>
              <Stack direction={'row'} spacing={5} padding={2}>
                <TextField
                  label="Replacement Period"
                  {...getFieldProps('replacementPeriod')}
                  error={Boolean(touched.replacementPeriod && errors.replacementPeriod)}
                  helperText={touched.replacementPeriod && errors.replacementPeriod}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Return Period"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps('returnPeriod')}
                  error={Boolean(touched.returnPeriod && errors.returnPeriod)}
                  helperText={touched.returnPeriod && errors.returnPeriod}
                />
              </Stack>

              <Box padding={2}>
                <Typography variant="h6" paddingBottom={1}>
                  Description
                </Typography>
                <Box style={{ border: '2px solid' }}>
                  <Editor
                    // editorState={EditorState.createWithContent(
                    //   ContentState.createFromBlockArray(convertFromHTML(`${product?.description}`))
                    // )}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Box>
              </Box>
            </Stack>

            <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
              <Box>
                <Typography variant="h6" paddingBottom={1}>
                  Product Images
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  {product.images?.map((item, index) => {
                    return (
                      <Box component={"img"} src={item} alt="product images" height="150px" sx={{ margin: '5px' }} />
                    );
                  })}
                </Box>
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple
                  maxFiles={3}
                  allowReorder
                  allowImagePreview
                  credits={false}
                  maxFileSize={'1MB'}
                  labelMaxFileSizeExceeded="File is too large"
                  labelMaxFileSize="Maximum file size is 1MB"
                  // server="/api"
                  name="files"
                  labelIdle="Drag & Drop your product files"
                />
              </Box>
              <Box>
                <Typography variant="h6" paddingBottom={1}>
                  Size Chart
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  <Box>
                    <img src={product.sizeChartFile} alt="product images" height="150px" style={{ margin: '5px' }} />
                  </Box>
                </Box>
                <FilePond
                  files={sizeFile}
                  onupdatefiles={setSizeFile}
                  allowMultiple={false}
                  maxFiles={1}
                  credits={false}
                  name="files"
                  maxFileSize={'1MB'}
                  labelMaxFileSizeExceeded="	
                  File is too large"
                  labelMaxFileSize="Maximum file size is 1MB"
                  labelIdle="Drag & Drop your size chart file"
                />
              </Box>
            </Stack>
            <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
              <Typography variant="h6">Product organization</Typography>
              <Stack direction={'row'} spacing={10} justifyContent="center">
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Category</Typography>
                  {/* Model popup */}
                  <Stack>
                    <CategoryModel setCategoryValue={setCategoryValue} />
                    <FormHelperText style={{ fontSize: '10px', textAlign: 'center' }}>
                      {product.categoryId ? product.categoryId : categoryValue}
                    </FormHelperText>
                  </Stack>

                  {/* Ends Popup  */}
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Featured</Typography>
                  <FormControl fullWidth>
                    <Select
                      {...getFieldProps('isFeatured')}
                      id="demo-simple-select-label"

                    // value={product.isFeatured ? 'true' : 'false'}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Popular</Typography>
                  <FormControl fullWidth>
                    <Select
                      {...getFieldProps('isPopular')}
                      id="demo-simple-select-label"

                    // value={product.isFeatured ? 'true' : 'false'}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>

              <Stack direction={'row'} spacing={10} justifyContent="center">
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6" minWidth={100}>
                    New Arrival
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      {...getFieldProps('newArrival')}
                      id="demo-simple-select-label"

                    // value={product.isFeatured ? 'true' : 'false'}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Status</Typography>
                  <FormControl>
                    <Select {...getFieldProps('isBlocked')} id="demo-select-small">
                      <MenuItem value="true">Blocked</MenuItem>
                      <MenuItem value="false">Active</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
              <Typography variant="h6">Variants</Typography>

              {addVariant?.map((item, index) => {
                const priceError = touched.addVariant?.[index]?.price && errors.addVariant?.[index]?.price;
                const sellingPriceError = touched.addVariant?.[index]?.sellingPrice && errors.addVariant?.[index]?.sellingPrice;
                const skuIdError = touched.addVariant?.[index]?.skuId && errors.addVariant?.[index]?.skuId;
                const stockError = touched.addVariant?.[index]?.stock && errors.addVariant?.[index]?.stock;
                const offerError = touched.addVariant?.[index]?.offer && errors.addVariant?.[index]?.offer;
                return (
                  <Box key={index} style={{ border: '1px solid', borderRadius: '5px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton aria-label="close" onClick={handleCloseVariant}>
                        <CloseIcon />
                      </IconButton>
                    </Box>

                    <Stack direction={'row'} spacing={5} padding={2} paddingTop={5}>
                      <TextField
                        label="Price"
                        fullWidth
                        defaultValue={item.price}
                        onChange={(e) => {
                          // addVariant[index].price = e.target.value;
                          // setAddVariant([...addVariant]);
                          onPriceChange(e, index)
                        }}
                        error={Boolean(priceError)}
                        helperText={priceError}
                      />
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{item.size}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          label="Age"
                          // onChange={handleChange}
                          onChange={(e) => {
                            addVariant[index].size = e.target.value;
                          }}
                        >
                          {sizes?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction={'row'} spacing={5} padding={2}>
                      <TextField
                        label="Selling Price"
                        fullWidth
                        defaultValue={item.sellingPrice}
                        onChange={(e) => {
                          onSellingPriceChange(e, index)
                        }}
                        error={Boolean(sellingPriceError)}
                        helperText={sellingPriceError}
                      />
                      <TextField
                        label="SKU ID"
                        fullWidth
                        defaultValue={item.skuId}
                        onChange={(e) => {
                          addVariant[index].skuId = e.target.value;
                          setAddVariant([...addVariant]);
                        }}
                        error={Boolean(skuIdError)}
                        helperText={skuIdError}
                      />
                    </Stack>
                    <Stack direction={'row'} spacing={5} padding={2}>
                      <TextField
                        type={"text"}
                        label="Offer"
                        disabled
                        fullWidth
                        value={item.offer}
                        error={Boolean(offerError)}
                        helperText={offerError}
                        onChange={(e) => {
                          addVariant[index].offer = e.target.value;
                          setAddVariant([...addVariant]);
                        }}
                      />
                      <TextField
                        label="Stock"
                        fullWidth
                        defaultValue={item.stock}
                        onChange={(e) => {
                          addVariant[index].stock = e.target.value;
                          setAddVariant([...addVariant]);
                        }}
                        error={Boolean(stockError)}
                        helperText={stockError}
                      />
                    </Stack>
                  </Box>
                );
              })}
              <Button onClick={handleAddVariant}>Add Variant</Button>
            </Stack>
            <Stack direction={'row'} justifyContent="center" paddingTop="50px">
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                Edit Product
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
        {/* Formik provider Ends  */}
      </Container>
    </div>
  );
};

export default EditProduct;
