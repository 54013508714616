import { Typography, Box, Grid, Stack } from '@mui/material';
import Noavatar from '../assets/images/avatar.jpg';
import Page from './Page';

export const UserBasicInfo = ({ userData }) => {
  return (
    <Page>
        <>
        <Grid container paddingTop={4} spacing={5}>
          <Grid item xs={12} sm={3} md={3} lg={4}>
            <Box sx={{
              display: "flex", justifyContent: "center",
              alignItems: "center"
            }}>
              <img src={userData?.avatar ? userData.avatar : Noavatar} alt="user" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={8}>
            <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
              <Stack direction={"column"}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Fullname
                </Typography>
                <Typography>
                  {userData?.fullname}
                </Typography>
              </Stack>
              <Stack direction={"column"}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Email
                </Typography>
                <Typography>
                  {userData?.email}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </>
    </Page>
  );
};
