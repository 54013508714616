import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, Typography, TextField, IconButton, InputAdornment, FormControlLabel, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const CssTextField = styled(TextField)({
    "& .MuiOutlinedInput-root ": {
        borderRadius: 2,
        boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.15)",
        fontFamily: "Roboto",
    }

});

const CssButton = styled(LoadingButton)({
    "&.MuiButtonBase-root": {
        borderRadius: 0,
        boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.15)",
        fontFamily: "Roboto",
    }
});

function ResetPassForm() {
    const navigate = useNavigate();
    const uninterceptedAxiosInstance = axios.create();
    const [showPassword, setShowPassword] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const ResetPassSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
            )
        })
    });

    const token = searchParams.get("token")

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            remember: true,
        },
        validationSchema: ResetPassSchema,
        onSubmit: async () => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/admin/reset-password/verify`, {
                    token,
                    password: values.confirmPassword
                });
                if (res.data.isError) {
                    toast.error(res.data.message);
                    setTimeout(() => {
                        navigate("/forgot-password", { replace: true })
                    }, 700);
                } else {
                    toast.success(res.data.message);
                    setTimeout(() => {
                        navigate("/login", { replace: true })
                    }, 700);
                }
            } catch (error) {
                toast.error(error.response.data.message);
                console.log(error.message);
            }
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <>

            <Typography gutterBottom textAlign="center" sx={{ width: "100%", color: "#9e2e2e", fontSize: "24px", fontFamily: "Roboto", fontWeight: 500 }}>
                Reset Password
            </Typography>

            <Typography sx={{ color: "#cb4d4d", mb: 5, width: "100%" }} textAlign="center">Enter your password below.</Typography>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>

                        <CssTextField
                            fullWidth
                            autoComplete={false}
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />

                        <CssTextField
                            fullWidth
                            autoComplete={false}
                            type={showPassword ? 'text' : 'password'}
                            label="Confirm Password"
                            {...getFieldProps('confirmPassword')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                        />
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" >
                        {/* <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            /> */}

                        <Link component={RouterLink} variant="subtitle2" to="/login" underline="hover" style={{marginTop:"20px"}}>
                            Remember Password?
                        </Link>
                    </Stack>

                    <CssButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                        Reset Password
                    </CssButton>
                </Form>
            </FormikProvider>
        </>
    );
}

export default ResetPassForm