import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import '../assets/css/style.css';

import {
  Typography,
  Card,
  Stack,
  Tooltip,
  Zoom,
  CircularProgress,
  Grid,
  Container,
  CardContent,
  Box,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  IconButton,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { ArrowBack } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Icon from '../components/Iconify';
import logo from '../assets/images/capricon_new.png';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

export default function OrderView() {
  const [orderData, setOrderData] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [updatedSubTotal, setUpdatedSubTotal] = useState(0);
  const [updatedDeliveryCharge, setUpdatedDeliveryCharge] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [courierId, setCourierId] = useState('');
  const [products, setProducts] = useState([]);
  const [returnedProducts, setReturnedProducts] = useState([]);
  const [returnResponded, setReturnResponded] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [replacementEnabled, setReplacementEnabled] = useState(false);
  const [isReplacement, setIsReplacement] = useState(false);
  const [quantitySelected, setQuantitySelected] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getOrder = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/order/admin`, {
        params: { id },
      })
      .then((res) => {
        console.log('order', res);
        setOrderData(res.data.data.order);
        setUpdatedSubTotal(res.data.data.order.subTotal);
        setUpdatedDeliveryCharge(res.data.data.order.deliveryCharge);
        setCourierId(res.data.data.order.courierId);
        const orderProducts = res.data.data.order.products;
        setReplacementEnabled(res.data.data.order.replacementEnabled);
        setIsReplacement(res.data.data.order.isReplacement);
        let productsReturned = res.data.data.order.returnedProducts;
        if (productsReturned) {
          productsReturned = productsReturned.map((item) => {
            if (['ACCEPTED', 'REJECTED'].includes(item.returnedStatus)) {
              setReturnResponded(true);
            }
            orderProducts.forEach((product) => {
              if (product.productId === item.productId && product.variantId === item.variantId) {
                item.name = product.name;
                item.skuId = product.skuId;
                item.sellingPrice = product.sellingPrice;
              }
            });
            return item;
          });
        }
        setProducts(orderProducts);
        setUpdatedProducts(orderProducts);
        if (productsReturned.length > 0) {
          setReturnedProducts(productsReturned);
          const temp = productsReturned.reduce((acc, item) => acc + item.sellingPrice * item.acceptedQuantity, 0);
          setRefundAmount(temp);
        } else if (res.data.data.order.deliveryStatus === 'CANCELLED') {
          setRefundAmount(res.data.data.order.grandTotal);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCourierId = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/order/admin/courier`, { id, courierId });
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  const handleChangeQuantity = (e, item) => {
    setReturnedProducts((current) =>
      current.map((obj) => {
        if (obj.productId === item.productId && obj.variantId === item.variantId) {
          return { ...obj, acceptedQuantity: e.target.value, rejectedQuantity: obj.requestedQuantity - e.target.value };
        }
        return obj;
      })
    );
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/order/admin/verify-return`, {
        orderId: orderData._id,
        refundAmount,
        products: returnedProducts,
      });
      toast.success(res.data.message);
      getOrder();
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setSubmitting(false);
    }
  };

  const handleRefundStatusChange = async () => {
    setSubmitting(true);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/order/admin/status`, {
        id,
        status: { type: 'refund', value: 'SUCCESS' },
        refundAmount,
      });
      toast.success(res.data.message);

      getOrder();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setSubmitting(false);
    }
  };

  const handleUpdteQuanitty = async () => {
    setSubmitting(true);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/order/update-quantity`, {
        orderId: id,
        products: updatedProducts,
        deliveryCharge: updatedDeliveryCharge,
      });
      setChecked(false);
      toast.success(res.data.message);
      getOrder();
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (returnedProducts && returnedProducts.length) {
      returnedProducts.forEach((product) => {
        if (product.acceptedQuantity === 0 && product.rejectedQuantity === 0) {
          setQuantitySelected(false);
        } else setQuantitySelected(true);
      });
    }
  }, [returnedProducts]);

  useEffect(() => {
    const tempSubTotal = updatedProducts.reduce((acc, item) => acc + item.sellingPrice * item.quantity, 0);
    setUpdatedSubTotal(tempSubTotal);
  }, [updatedProducts]);

  useEffect(() => {
    getOrder();
  }, [id]);

  return (
    <Page title="Order">
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Scrollbar>
          <Container maxWidth="lg">
            <Stack direction="row" justifyContent="flex-end" mb={1} spacing={2} paddingX={3} paddingY={1}>
              <Stack className="print-hide" direction="row" spacing={2}>
                <TextField
                  size="small"
                  style={{ width: '300px' }}
                  type="text"
                  component="div"
                  label="AWB code"
                  value={courierId}
                  onInput={(e) => setCourierId(e.target.value)}
                />
                <Button variant="contained" onClick={updateCourierId} style={{ maxHeight: '35px' }}>
                  Update
                </Button>
              </Stack>
              <ReactToPrint
                trigger={() => (
                  <Button
                    style={{ maxHeight: '35px' }}
                    variant="contained"
                    disabled={checked}
                    endIcon={<LocalPrintshopIcon />}
                  >
                    {checked ? 'Disable Edit Mode to Print' : 'Print'}
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" paddingX={3}>
              {orderData.deliveryStatus !== 'CANCELLED' ? (
                <Typography sx={{ fontWeight: 'bold', fontSize: '24px' }}>
                  {isReplacement ? 'REPLACEMENT ORDER' : 'NORMAL ORDER'}
                </Typography>
              ) : (
                <Typography sx={{ fontWeight: 'bold', fontSize: '24px' }}>CANCELLED ORDER</Typography>
              )}
              <IconButton onClick={() => navigate(-1)} className="print-hide">
                <ArrowBack sx={{ fontSize: 34 }} />
              </IconButton>
            </Stack>
            <Box ref={componentRef} padding={3}>
              <Grid
                container
                direction="row"
                xs={12}
                padding={2}
                style={{ backgroundColor: '#fff0ed', width: '100%', height: '220px' }}
              >
                <Grid
                  item
                  xs={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ height: '100%', width: '15%' }}
                >
                  <img alt="logo" src={logo} style={{ maxHeight: '90%' }} />
                </Grid>
                <Grid
                  item
                  xs={10}
                  direction="row"
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '100%', width: '82%' }}
                  paddingLeft={3}
                  paddingBottom={4}
                >
                  <Stack direction="column" justifyContent="center" style={{ height: '100%' }}>
                    <Typography style={{ fontSize: '30px', fontWeight: 500 }}>{orderData.invoiceNumber}</Typography>
                    <Typography>Invoice Date : {orderData.date}</Typography>
                    {orderData.paymentRemark && <Typography>Payment Remark : {orderData.paymentRemark}</Typography>}
                    <Typography>Payment Mode : {orderData.paymentType}</Typography>
                    {isReplacement && (
                      <Button
                        variant="outlined"
                        onClick={() => navigate(`/dashboard/order/${orderData.referenceOrder}`)}
                        size="small"
                        sx={{ maxWidth: '180px', height: '30px' }}
                        className="print-hide"
                        startIcon={<RemoveRedEyeOutlinedIcon />}
                      >
                        Original Order
                      </Button>
                    )}
                    {replacementEnabled && (
                      <Button
                        variant="outlined"
                        onClick={() => navigate(`/dashboard/order-replace/${id}`)}
                        size="small"
                        sx={{ maxWidth: '180px', marginTop: '10px', height: '30px' }}
                        className="print-hide"
                        startIcon={<RemoveRedEyeOutlinedIcon />}
                      >
                        Replacement Order
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container xs={12} direction="row" paddingx="50px" paddingY="20px" style={{ width: '100%' }}>
                <Grid item xs={6} style={{ width: '50%' }} padding={2} direction="column">
                  <Typography marginBottom={2} style={{ fontSize: '20px', fontWeight: 500, color: '#c86161' }}>
                    OFFICE ADDRESS
                  </Typography>
                  <Stack direction="column">
                    <Typography>Capricorn28</Typography>
                    <Typography>Indoseas Crest</Typography>
                    <Typography>Flat No. 1A, Near Kavu Bus stop, Chevayur,</Typography>
                    <Typography>Kozhikode</Typography>
                    <Typography>Kerala-673017</Typography>
                    <Typography> Phone: +91 7592020805</Typography>
                    <Typography> Email: capricorn28official@gmail.com</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} style={{ width: '50%' }} padding={2} direction="column">
                  <Typography marginBottom={2} style={{ fontSize: '20px', fontWeight: 500, color: '#c86161' }}>
                    SHIP TO
                  </Typography>
                  <Stack direction="column">
                    <Typography>{orderData.shippingAddress?.fullname?.toUpperCase()}</Typography>
                    <Typography>{orderData.shippingAddress?.contactNumber}</Typography>
                    <Typography>{orderData.shippingAddress?.address}</Typography>
                    <Typography>{orderData.shippingAddress?.city}</Typography>
                    <Typography>{orderData.shippingAddress?.country}</Typography>
                    {orderData.shippingAddress?.landmark && (
                      <Typography>Landmark:{orderData.shippingAddress?.landmark}</Typography>
                    )}
                    <Typography>{orderData.shippingAddress?.postCode}</Typography>
                  </Stack>
                </Grid>
              </Grid>

              {['NA', 'PENDING', 'PACKAGE IN PROGRESS', 'SHIPPED'].includes(orderData.deliveryStatus) && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ marginY: '20px', width: '100%', paddingX: '20px' }}
                >
                  <FormControlLabel
                    className="print-hide"
                    value="Edit"
                    control={
                      <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label="Edit"
                    labelPlacement="start"
                  />
                </Stack>
              )}

              {!checked && (
                <TableContainer>
                  <Table style={{ minWidth: '00px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>SKU ID</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products?.map((item, key) => (
                        <>
                          <TableRow key={key}>
                            <TableCell sx={{ width: '80px' }}>{key + 1}</TableCell>
                            <TableCell sx={{ width: '470px' }}>{item.name}</TableCell>
                            <TableCell sx={{ width: '110px' }}>{item.skuId}</TableCell>
                            <TableCell sx={{ width: '100px' }}>{item.size}</TableCell>
                            <TableCell sx={{ width: '130px' }}>{item.sellingPrice}</TableCell>
                            <TableCell sx={{ width: '80px' }}>{item.quantity}</TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {!checked && (
                <Box marginTop={8} paddingLeft={{ lg: 15, md: 10, sm: 2, xs: 0 }}>
                  <Grid container xs={12} spacing={10} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Stack direction="column" alignItems="center" width="100%" spacing={2}>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography>Payment Status:</Typography>
                          <Typography>{orderData.paymentStatus}</Typography>
                        </Stack>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography>Delivery Status:</Typography>
                          <Typography>{orderData.deliveryStatus}</Typography>
                        </Stack>
                        <Stack
                          className="print-hide"
                          direction="row"
                          width="100%"
                          spacing={5}
                          justifyContent="space-between"
                        >
                          <Typography>Refund Status:</Typography>
                          <Typography>{orderData.refundStatus}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack direction="column" alignItems="center" width="100%" spacing={2}>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography>Subtotal:</Typography>
                          <Typography>Rs: {orderData.subTotal}</Typography>
                        </Stack>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography>Delivery Charge:</Typography>
                          <Typography>{orderData.deliveryCharge ? orderData.deliveryCharge : 'Free'}</Typography>
                        </Stack>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography variant="h6">Grand Total:</Typography>
                          <Typography variant="h6">Rs: {orderData.grandTotal}</Typography>
                        </Stack>

                        {orderData.deliveryStatus === 'CANCELLED' && orderData.refundStatus === 'SUCCESS' && (
                          <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                            <Typography variant="h6">Refund Amount:</Typography>
                            <Typography variant="h6">Rs: {orderData.refundAmount}</Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {checked && (
                <TableContainer>
                  <Table style={{ minWidth: '00px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>SKU ID</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Updated Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products?.map((item, key) => (
                        <>
                          <TableRow key={key}>
                            <TableCell sx={{ width: '80px' }}>{key + 1}</TableCell>
                            <TableCell sx={{ width: '470px' }}>{item.name}</TableCell>
                            <TableCell sx={{ width: '110px' }}>{item.skuId}</TableCell>
                            <TableCell sx={{ width: '100px' }}>{item.size}</TableCell>
                            <TableCell sx={{ width: '130px' }}>{item.sellingPrice}</TableCell>
                            <TableCell sx={{ width: '80px' }}>{item.quantity}</TableCell>
                            <TableCell sx={{ width: '80px' }}>
                              <TextField
                                id="outlined-number"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                size="small"
                                defaultValue={item.quantity}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const newProducts = updatedProducts.map((product) => {
                                    if (product.productId === item.productId && product.variantId === item.variantId) {
                                      return { ...product, quantity: value };
                                    }
                                    return product;
                                  });
                                  setUpdatedProducts(newProducts);
                                  console.log(newProducts);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {checked && (
                <Box marginTop={8} paddingLeft={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                  <Grid container xs={12} spacing={5} justifyContent="space-between">
                    <Grid item xs={5}>
                      <Stack direction="column" alignItems="center" width="100%" spacing={2}>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography>Payment Status:</Typography>
                          <Typography>{orderData.paymentStatus}</Typography>
                        </Stack>
                        <Stack direction="row" width="100%" spacing={5} justifyContent="space-between">
                          <Typography>Delivery Status:</Typography>
                          <Typography>{orderData.deliveryStatus}</Typography>
                        </Stack>
                        <Stack
                          className="print-hide"
                          direction="row"
                          width="100%"
                          spacing={5}
                          justifyContent="space-between"
                        >
                          <Typography>Refund Status:</Typography>
                          <Typography>{orderData.refundStatus}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={7}>
                      <Stack direction="column" alignItems="center" width="100%" spacing={2}>
                        <Stack direction="row" width="100%" justifyContent="space-between">
                          <Typography>Subtotal:</Typography>
                          <Stack direction="row" width="100%" spacing={1} justifyContent="flex-end">
                            <Typography>Rs: {orderData.subTotal}</Typography>
                            <Typography color="text.secondary">({updatedSubTotal})</Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" width="100%" justifyContent="space-between">
                          <Typography>Delivery Charge:</Typography>
                          <Stack direction="row" width="100%" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Typography>{orderData.deliveryCharge ? orderData.deliveryCharge : 'Free'}</Typography>
                            <TextField
                              id="outlined-number"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                width: '80px',
                              }}
                              variant="outlined"
                              size="small"
                              defaultValue={updatedDeliveryCharge}
                              onChange={(e) => {
                                setUpdatedDeliveryCharge(e.target.value);
                              }}
                            />
                          </Stack>
                        </Stack>
                        <Stack direction="row" width="100%" justifyContent="space-between">
                          <Typography variant="h6">Grand Total:</Typography>
                          <Stack direction="row" width="100%" spacing={1} justifyContent="flex-end">
                            <Typography variant="h6">Rs: {orderData.grandTotal}</Typography>
                            <Typography variant="h6" color="text.secondary">
                              ({Number(updatedSubTotal) + Number(updatedDeliveryCharge)})
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" width="100%" justifyContent="flex-end">
                        <Button variant="contained" disabled={submitting} onClick={handleUpdteQuanitty}>
                          Update
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {!!returnedProducts.length && (
                <Grid container mt={2}>
                  <Grid item direction="row" xs={12} padding={1} style={{ backgroundColor: '#fff0ed', width: '100%' }}>
                    <Typography variant="h6">Return</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table style={{ minWidth: '00px' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>SN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>SKU ID</TableCell>
                            <TableCell>Unit Price</TableCell>
                            <TableCell>Requested Quantity</TableCell>
                            <TableCell>Accepted Quantity</TableCell>
                            <TableCell>Rejected Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {returnedProducts?.map((item, key) => (
                            <TableRow key={key}>
                              <TableCell sx={{ width: '80px' }}>{key + 1}</TableCell>
                              <TableCell sx={{ width: '470px' }}>{item.name}</TableCell>
                              <TableCell sx={{ width: '110px' }}>{item.skuId}</TableCell>
                              <TableCell sx={{ width: '80px' }}>{item.sellingPrice}</TableCell>
                              {returnResponded && (
                                <TableCell sx={{ width: '80px' }}>{item.requestedQuantity}</TableCell>
                              )}
                              {returnResponded && <TableCell sx={{ width: '80px' }}>{item.acceptedQuantity}</TableCell>}
                              {returnResponded && <TableCell sx={{ width: '80px' }}>{item.rejectedQuantity}</TableCell>}
                              {!returnResponded && (
                                <TableCell sx={{ width: '80px' }}>{item.requestedQuantity}</TableCell>
                              )}
                              {!returnResponded && (
                                <TableCell sx={{ width: '80px' }}>
                                  <TextField
                                    select
                                    type="number"
                                    fullWidth
                                    size="small"
                                    onChange={(e) => handleChangeQuantity(e, item)}
                                  >
                                    {Array.from(Array(item.requestedQuantity + 1), (e, i) => (
                                      <MenuItem value={i}>{i}</MenuItem>
                                    ))}
                                  </TextField>
                                </TableCell>
                              )}
                              {!returnResponded && (
                                <TableCell sx={{ width: '80px' }}>{item.rejectedQuantity}</TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {!returnResponded && (
                    <Grid item xs={12}>
                      <Stack sx={{ width: '100%' }} direction="row" justifyContent="end" padding={2}>
                        <Stack justifyContent={'space-between'} spacing={2} direction="row" alignItems={'center'}>
                          {/* <Typography>Refund Amount</Typography> */}
                          {/* <TextField
                            value={refundAmount}
                            onChange={(e) => setRefundAmount(e.target.value)}
                            size="small"
                            sx={{ width: '150px' }}
                            type="number"
                          /> */}
                          <LoadingButton
                            variant="contained"
                            loading={submitting}
                            disabled={!quantitySelected}
                            onClick={handleSubmit}
                          >
                            Submit
                          </LoadingButton>
                        </Stack>
                      </Stack>
                    </Grid>
                  )}
                  {returnResponded && orderData.refundStatus === 'SUCCESS' && (
                    <Grid item xs={12}>
                      <Stack sx={{ width: '100%' }} direction="row" justifyContent="end" padding={2}>
                        <Stack justifyContent={'space-between'} spacing={2} direction="row" alignItems={'center'}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Refund Amount
                          </Typography>
                          <Typography variant="h6">{`Rs: ${orderData.refundAmount || 0}`}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  )}
                  {returnResponded && orderData.refundStatus === 'PENDING' && (
                    <Grid item xs={12}>
                      <Stack sx={{ width: '100%' }} direction="row" justifyContent="end" padding={2}>
                        <Stack justifyContent={'space-between'} spacing={2} direction="row" alignItems={'center'}>
                          <Typography>Refund Amount</Typography>
                          <TextField
                            value={refundAmount}
                            onChange={(e) => setRefundAmount(e.target.value)}
                            size="small"
                            sx={{ width: '150px' }}
                            type="number"
                          />
                          <LoadingButton
                            variant="contained"
                            loading={submitting}
                            disabled={!quantitySelected || !refundAmount}
                            onClick={handleRefundStatusChange}
                          >
                            Submit
                          </LoadingButton>
                        </Stack>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              )}

              {orderData.deliveryStatus === 'CANCELLED' && orderData.refundStatus === 'PENDING' && (
                <Grid item xs={12}>
                  <Stack sx={{ width: '100%' }} direction="row" justifyContent="end" padding={2}>
                    <Stack justifyContent={'space-between'} spacing={2} direction="row" alignItems={'center'}>
                      <Typography>Refund Amount</Typography>
                      <TextField
                        value={refundAmount}
                        onChange={(e) => setRefundAmount(e.target.value)}
                        size="small"
                        sx={{ width: '150px' }}
                        type="number"
                      />
                      <LoadingButton
                        variant="contained"
                        loading={submitting}
                        disabled={!refundAmount}
                        onClick={handleRefundStatusChange}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
              )}

              <Container>
                <Stack xs={12} marginTop={4}>
                  <Typography variant="h6">Declaration</Typography>
                  <Typography variant="p">
                    We declre that this invoice shows actual price of goods/packs described. All goods/packs are being
                    purchased for internal/personal use, not for re-sale
                  </Typography>
                </Stack>
              </Container>

              {/* <>
            <Card sx={{ background: '#e0e2e5' }}>
              <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-end" padding={1}>
                <Tooltip TransitionComponent={Zoom} title={'Close'}>
                  <CloseIcon onClick={() => navigate(-1)} cursor="pointer" />
                </Tooltip>
              </Stack>
              <CardContent>
                <Stack container spacing={3} xs={12}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Order ID</Typography>
                      <Typography>{orderData.orderId}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Invoice Number</Typography>
                      <Typography>{orderData.invoiceNumber}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Date</Typography>
                      <Typography>{orderData.date}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>User ID</Typography>
                      <Typography>{orderData.userId}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Max Price</Typography>
                      <Typography>{orderData.maxPrice}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Sub Total</Typography>
                      <Typography>{orderData.subTotal}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Delivery Charge</Typography>
                      <Typography>{orderData.deliveryCharge}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Grand Total</Typography>
                      <Typography>{orderData.grandTotal}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Payment Status</Typography>
                      <Typography>{orderData.paymentStatus}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Delivery Status</Typography>
                      <Typography>{orderData.deliveryStatus}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Refund Status</Typography>
                      <Typography>{orderData.refundStatus}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Stack direction="column">
                      <Typography style={{ fontWeight: 'bold' }}>Updated On</Typography>
                      <Typography>{moment(orderData.updatedAt).format('MMMM DD, YYYY')}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={1}>
                      <Typography style={{ fontWeight: 'bold' }}>Courier ID</Typography>
                      <Stack direction="row" spacing={1}>
                        <TextField
                          size="small"
                          style={{ maxWidth: '100px' }}
                          type="text"
                          component="div"
                          label="Courier ID"
                          value={courierId}
                          onInput={(e) => setCourierId(e.target.value)}
                        />
                        <Button variant="contained" onClick={updateCourierId} style={{ maxHeight: '35px' }}>
                          Update
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid xs={12} item>
                    <Stack>
                      <Typography style={{ fontWeight: 'bold' }}>Billing Address</Typography>
                    </Stack>
                    <Box sx={{ padding: 1, borderRadius: '10px', border: '1px solid gray' }}>
                      <Card>
                        <Grid container margin={1} xs={12} spacing={1}>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Fullname</Typography>
                              <Typography>{orderData.billingAddress?.fullname?.toUpperCase()}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Contact Number</Typography>
                              <Typography>{orderData.billingAddress?.contactNumber}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>Email</Typography>
                              <Typography>{orderData.billingAddress?.email}</Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </Grid>

                  <Grid xs={12} item>
                    <Stack>
                      <Typography style={{ fontWeight: 'bold' }}>Shipping Address</Typography>
                    </Stack>
                    <Box sx={{ padding: 1, borderRadius: '10px', border: '1px solid gray' }}>
                      <Card>
                        <Grid container margin={1} xs={12} spacing={1}>
                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Fullname</Typography>
                              <Typography>{orderData.shippingAddress?.fullname?.toUpperCase()}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Contact Number</Typography>
                              <Typography>{orderData.shippingAddress?.contactNumber}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>Email</Typography>
                              <Typography>{orderData.shippingAddress?.email}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Country</Typography>
                              <Typography>{orderData.shippingAddress?.country}</Typography>
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>City</Typography>
                              <Typography>{orderData.shippingAddress?.city}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Post Code</Typography>
                              <Typography>{orderData.shippingAddress?.postCode}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Stack direction="column">
                              <Typography style={{ fontWeight: 'bold' }}>Address</Typography>
                              <Typography>{orderData.shippingAddress?.address}</Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <Typography style={{ fontWeight: 'bold' }}>Products : {orderData?.products?.length}</Typography>
                    </Stack>
                    <Box sx={{ padding: 1, borderRadius: '10px', border: '1px solid gray' }}>
                      <Grid container spacing={1} xs={12}>
                        {orderData?.products?.map((item, key) => {
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <Card>
                                <Stack padding={1} direction="column">
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Name</Typography>
                                    <Typography>{item.name}</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Price</Typography>
                                    <Typography>{item.price}</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Product ID</Typography>
                                    <Typography style={{ wordBreak: 'break-all' }}>{item.productId}</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Variant ID</Typography>
                                    <Typography style={{ wordBreak: 'break-all' }}>{item.variantId}</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Size</Typography>
                                    <Typography>{item.size}</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Quantity</Typography>
                                    <Typography>{item.quantity}</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontWeight: 'bold' }}>Selling Price</Typography>
                                    <Typography>{item.sellingPrice}</Typography>
                                  </Stack>
                                </Stack>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Stack>
              </CardContent>
            </Card>
          </> */}
            </Box>
          </Container>
        </Scrollbar>
      )}
    </Page>
  );
}
