import { Edit, TableRowsTwoTone } from '@mui/icons-material';
import { Button, Card, CircularProgress, Stack, TextField, Typography, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function ProductSizes() {
  const [editMode, setEditMode] = React.useState(false);
  const [sizes, setSizes] = React.useState('');
  const [sizeArray, setSizeArray] = React.useState([]);
  const [newSizeArray, setNewSizeArray] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const updateSizes = async () => {
    try {
      setDisableButton(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/settings/size/add`, { size: newSizeArray });
      console.log(res);
      toast.success('Sizes updated successfully');
      getSizes();
    } catch (error) {
      console.log(error);
    }
  };
  const getSizes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
      console.log(res);
      setIsLoading(false);
      const temp = res.data.data.sizes;
      if (temp.length) {
        setSizes(temp.join(','));
        setSizeArray(temp);
      }
      setEditMode(false);
      setDisableButton(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sizes.length > 0) {
      let temp = sizes.split(',');
      temp = temp.map((item) => item.trim().toUpperCase());
      temp = temp.filter((item) => item.length > 0);
      setNewSizeArray(temp);
      const unique = [...new Set(temp)];
      if (unique.length !== temp.length) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  }, [sizes, editMode]);

  useEffect(() => {
    getSizes();
  }, []);

  return (
    <Card sx={{ backgroundColor: '#FCEEED', ...(!editMode && { height: '250px' }) }}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <CircularProgress />
        </div>
      ) : (
        <Stack sx={{ display: 'flex', direction: 'column', width: '100%', alignItems: 'center' }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            sx={{ width: '100%', backgroundColor: '#FF9393', padding: 1 }}
          >
            <Typography variant="h6">Product Sizes</Typography>
          </Stack>
          {!editMode && (
            <Stack sx={{ width: '100%', padding: 2 }} spacing={2} alignItems="center">
              <Grid container sx={{ height: '125px', overflowY: 'scroll', scrollBehavior: 'smooth' }}>
                {sizeArray.map((size, index) => (
                  <Grid Item xs={4} key={index}>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      sx={{ border: '1px solid rgba(255, 147, 147, 0.5)', padding: 0.5, borderRadius: 1, margin: 0.5 }}
                    >
                      <Typography>{index + 1}.</Typography> &nbsp;
                      <Typography sx={{ fontWeight: 'bold' }}>{size}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: '#FF9393',
                  color: '#000',
                  boxShadow: 'none',
                  '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                }}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            </Stack>
          )}

          {editMode && (
            <Stack sx={{ width: '100%', padding: 2 }} spacing={2} alignItems="center">
              <Grid container>
                {newSizeArray.map((size, index) => (
                  <Grid Item xs={4} key={index}>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      sx={{ border: '1px solid rgba(255, 147, 147, 0.5)', padding: 0.5, borderRadius: 1, margin: 0.5 }}
                    >
                      <Typography>{index + 1}.</Typography> &nbsp;
                      <Typography sx={{ fontWeight: 'bold' }}>{size}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <TextField
                fullWidth
                label="Separate sizes with comma"
                name="sizes"
                onChange={(e) => setSizes(e.target.value)}
                type="text"
                value={sizes}
                variant="outlined"
                multiline
                sx={{
                  textTransform: 'uppercase',
                }}
              />
              <Stack direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    bgcolor: '#FF9393',
                    color: '#000',
                    width: '70px',
                    boxShadow: 'none',
                    '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                  }}
                  onClick={() => {
                    setEditMode(false);
                    setNewSizeArray(sizeArray);
                    setSizes(sizeArray.join(','));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  disabled={disableButton}
                  sx={{
                    bgcolor: '#FF9393',
                    color: '#000',
                    width: '70px',
                    boxShadow: 'none',
                    '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                  }}
                  onClick={() => updateSizes()}
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Card>
  );
}
