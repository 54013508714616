import { Edit, TableRowsTwoTone } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import ProductSizes from '../components/ProductSizes';
import Page from '../components/Page';
import DeliveryChargeSettings from '../DeliveryChargeSettings';
import PaymentMethodSettings from '../components/PaymentMethodSettings';

export default function Settings() {
  const [editMode, setEditMode] = React.useState(false);
  const [sizes, setSizes] = React.useState('');
  const [sizeArray, setSizeArray] = React.useState([]);
  const [newSizeArray, setNewSizeArray] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const updateSizes = async () => {
    try {
      setDisableButton(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/settings/add`, { size: newSizeArray });
      console.log(res);
      toast.success('Sizes updated successfully');
      getSizes();
    } catch (error) {
      console.log(error);
    }
  };
  const getSizes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
      console.log(res);
      setIsLoading(false);
      const temp = res.data.data.sizes;
      if (temp.length) {
        setSizes(temp.join(','));
        setSizeArray(temp);
      }
      setEditMode(false);
      setDisableButton(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sizes.length > 0) {
      let temp = sizes.split(',');
      temp = temp.map((item) => item.trim().toUpperCase());
      temp = temp.filter((item) => item.length > 0);
      setNewSizeArray(temp);
      const unique = [...new Set(temp)];
      if (unique.length !== temp.length) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  }, [sizes, editMode]);

  useEffect(() => {
    getSizes();
  }, []);

  return (
    <Page title="Settings">
      <Container  maxWidth="xl">
        <Stack direction="row" spacing={2}>
          <Typography paddingBottom={1} variant="h5">
            Settings
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <ProductSizes />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DeliveryChargeSettings />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PaymentMethodSettings />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
