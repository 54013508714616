import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, Typography, TextField, IconButton, InputAdornment, FormControlLabel, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root ": {
    borderRadius: 2,
    boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.15)",
    fontFamily: "Roboto",
  }

});

const CssButton = styled(LoadingButton)({
  "&.MuiButtonBase-root": {
    borderRadius: 0,
    boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.15)",
    fontFamily: "Roboto",
  }
});



export default function LoginForm() {
  const navigate = useNavigate();
  const uninterceptedAxiosInstance = axios.create();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await uninterceptedAxiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/admin/login`, values);
        if (res.data.isError) {
          console.log(res.data.message);
          toast.error(res.data.message);
        } else {
          localStorage.setItem('accessToken', res.data.data.accessToken);
          localStorage.setItem('profile', JSON.stringify(res.data.data));
          navigate('/dashboard/app', { replace: true });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>

      <Typography gutterBottom textAlign="center" sx={{ width: "100%", color: "#9e2e2e", fontSize: "24px", fontFamily: "Roboto", fontWeight: 500 }}>
        Sign in to CAPRICORN28
      </Typography>

      <Typography sx={{ color: "#cb4d4d", mb: 5, width: "100%" }} textAlign="center">Enter your details below.</Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <CssTextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <CssTextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" >
            {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

            <Link component={RouterLink} variant="subtitle2" to="/forgot-password" underline="hover" style={{marginTop:"20px"}}>
              Forgot password?
            </Link>
          </Stack>

          <CssButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </CssButton>
        </Form>
      </FormikProvider>
    </>
  );
}
