import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import UserView from './pages/UserView';
import Eshop from './pages/Eshop';
import ProductPage from './pages/ProductPage';
import ProductDetail from './pages/ProductDetail';
import Orders from './pages/Orders';
import OrderView from './pages/OrderView';
import ReplaceOrder from './pages/ReplaceOrderView';
import Returns from './pages/Returns';
import Refunds from './pages/Refunds';
import Settings from './pages/Settings';
import Admins from './pages/Admins';
import Profile from './pages/Profile';
import ViewAdmin from './pages/ViewAdmin';
import { LoginForm } from './sections/auth/login';
import ForgotPasswordFrom from './sections/auth/forgotPassword/ForgotPasswordFrom';
import ResetPassForm from './sections/auth/resetPassword/ResetPassForm';

// ----------------------------------------------------------------------
// routes
export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'user/:id', element: <UserView /> },
        // { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'eshop', element: <Eshop /> },
        { path: 'eshop/product/:id', element: <ProductDetail /> },
        { path: 'add/products', element: <ProductPage /> },
        { path: 'orders', element: <Orders /> },
        { path: 'returns', element: <Returns /> },
        { path: 'refunds', element: <Refunds /> },
        { path: 'order/:id', element: <OrderView /> },
        { path: 'order-replace/:id', element: <ReplaceOrder /> },
        { path: 'settings', element: <Settings /> },
        { path: 'profile', element: <Profile /> },
        { path: 'account', element: <Admins /> },
        { path: 'account/view/:id', element: <ViewAdmin /> },

      ],
    },
    {
      path: '/',
      element: <Login />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <LoginForm /> },
        { path: 'login', element: token ? <Navigate to="/dashboard/app" /> : <LoginForm /> },
        { path: 'forgot-password', element: <ForgotPasswordFrom /> },
        { path: 'reset-pass', element: <ResetPassForm /> },

      ],
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
