import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function DeliveryChargeSettings() {
  const [editMode, setEditMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [freeDeliveyThreshold, setFreeDeliveyThreshold] = React.useState(0);
  const [deliveryCharge, setDeliveryCharge] = React.useState(0);
  const [disableButton, setDisableButton] = React.useState(false);

  const getSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
      console.log(res);
      setIsLoading(false);
      setFreeDeliveyThreshold(res.data.data.freeDeliveyThreshold);
      setDeliveryCharge(res.data.data.deliveryCharge);
      setEditMode(false);
      setDisableButton(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSettings = async () => {
    try {
      setDisableButton(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/settings/delivery-charge/add`, {
        freeDeliveyThreshold,
        deliveryCharge,
      });
      console.log(res);
      toast.success('Settings updated successfully');
      getSettings();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Card sx={{ backgroundColor: '#FCEEED', height: '250px' }}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <CircularProgress />
        </div>
      ) : (
        <Stack sx={{ display: 'flex', direction: 'column', width: '100%', alignItems: 'center' }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            sx={{ width: '100%', backgroundColor: '#FF9393', padding: 1 }}
          >
            <Typography variant="h6">Delivery Charge</Typography>
          </Stack>
          {!editMode && (
            <Stack
              sx={{ width: '100%', padding: 2, marginTop: '20px' }}
              spacing={2}
              alignItems="center"
              justifyContent={'center'}
            >
              <Grid container paddingX={1} spacing={1}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ border: '1px solid rgba(255, 147, 147, 0.5)', padding: 0.5, borderRadius: 1, margin: 0.5 }}
                  >
                    <Typography variant="body1">Delivery Charge</Typography>
                    <Typography variant="body1">₹{deliveryCharge}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ border: '1px solid rgba(255, 147, 147, 0.5)', padding: 0.5, borderRadius: 1, margin: 0.5 }}
                  >
                    <Typography variant="body1">Minimum Amount:</Typography>
                    <Typography variant="body1">₹{freeDeliveyThreshold}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '22px' }}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        bgcolor: '#FF9393',
                        color: '#000',
                        boxShadow: 'none',
                        '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                      }}
                      onClick={() => setEditMode(true)}
                    >
                      Edit
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          )}
          {editMode && (
            <Stack
              sx={{ width: '100%', padding: 2, marginTop: '20px' }}
              spacing={2}
              alignItems="center"
              justifyContent={'center'}
            >
              <Grid container paddingX={1} spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <TextField
                      label="Delivery Charge"
                      variant="outlined"
                      size="small"
                      value={deliveryCharge}
                      onChange={(e) => setDeliveryCharge(e.target.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <TextField
                      label="Minimum Amount"
                      variant="outlined"
                      size="small"
                      value={freeDeliveyThreshold}
                      onChange={(e) => setFreeDeliveyThreshold(e.target.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center" spacing={2} alignItems="center" marginTop={2.5}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        bgcolor: '#FF9393',
                        color: '#000',
                        boxShadow: 'none',
                        '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                      }}
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={disableButton}
                      sx={{
                        bgcolor: '#FF9393',
                        color: '#000',
                        boxShadow: 'none',
                        '&:hover': { bgcolor: '#b36060', color: '#F2EEEE' },
                      }}
                      onClick={updateSettings}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Stack>
      )}
    </Card>
  );
}
