import { Stack, Box, Typography, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import EditProduct from '../components/EditProduct';

const ProductDetail = () => {
  const [getProducts, setGetProducts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const { id } = useParams();
  // console.log('projectId', projectId);
  const getProductsById = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/product/`, { params: { productId: id } }).then((res) => {
      console.log('res', res.data);
      setGetProducts(res.data.data.product);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getProductsById();
  }, []);

  return <>

    <EditProduct product={getProducts} />
  </>
};

export default ProductDetail;
