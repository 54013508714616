import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Container, Stack, Typography, Box, Tabs, Tab, CircularProgress } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import Page from '../components/Page';
import Noavatar from '../assets/images/avatar.jpg';
// import { UserBooking } from '../components/UserBooking';
import { UserBasicInfo } from '../components/UserBasicInfo';
// import { UserCoins } from '../components/UserCoins';
// import { UserAlbum } from '../components/UserAlbum';

const UserView = () => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState('1');

  const { id } = useParams();
  console.log('id', id);
  const getUserById = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/`, { params: { userId: id } }).then((res) => {
      console.log('res', res);
      setUserData(res.data.data.user);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getUserById();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="User">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container key={id} maxWidth ="xl">
            <Card sx={{ maxHeight: '100%' }}>

              <Stack direction={"row"} justifyContent="space-between" paddingX={2} paddingTop={2}>
                <Typography variant="h6">
                  {userData?.fullname?.toUpperCase()}
                </Typography>
                <Stack direction={"row"} justifyContent="space-between" spacing={2}>
                <Button
                  variant="contained"
                    color={userData?.isBlocked ? 'error' : 'success'}
                  disableTouchRipple
                >
                  {userData?.isBlocked ? 'Blocked' : 'Active'}
                </Button>
                  <Link to="/dashboard/user">
                    <ArrowBackIcon style={{ fontSize: '35px' }} />
                  </Link>
                </Stack>
              </Stack>
              <Box sx={{ width: '100%' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Basic Information" value="1" />
                    {/* <Tab label="Albums" value="2" /> */}
                    {/* <Tab label="Booking" value="3" /> */}
                    {/* <Tab label="VME Coins" value="4" /> */}
                  </TabList>
                </Box>
                  <TabPanel value="1"><UserBasicInfo userData={userData} /></TabPanel>
                {/* <TabPanel value="2">{<UserAlbum />}</TabPanel> */}
                {/* <TabPanel value="3">{<UserBooking />}</TabPanel> */}
                {/* <TabPanel value="4">{<UserCoins />}</TabPanel> */}
              </TabContext>
            </Box>
          </Card>
        </Container>
      )}
    </Page>
  );
};

export default UserView;
