import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// Import the plugin code
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import CloseIcon from '@mui/icons-material/Close';
//  React draft editor
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CategoryModel from './CategoryModel';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);


const productSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  brandName: Yup.string().required('Brand name is required'),
  shortName: Yup.string().required('Short name is required'),
  replacementPeriod: Yup.number().typeError("Replacement Period must be a valid number").required("Replacement Period is required"),
  returnPeriod: Yup.number().typeError("Return Period must be a valid number").required("Return Period is required"),
  isFeatured: Yup.string().required("Featured is required"),
  isPopular: Yup.string().required("Popular is required"),
  newArrival: Yup.string().required("New arrival is required"),
  isBlocked: Yup.string().required("Status is required"),

  addVariant: Yup.array().of(
    Yup.object().shape({
      price: Yup.number()
        .typeError('Price must be a number')
        .required('Price is required'),
      sellingPrice: Yup.number()
        .typeError('Selling Price must be a number')
        .required('Selling Price is required'),
      stock: Yup.number()
        .typeError('Stock must be a number')
        .required('Stock is required'),
      skuId: Yup.string().required('SKU ID is required'),
      offer: Yup.string().required('Offer Percentage is required'),
      size: Yup.string().required('Size is required'),
    })
  ),
});

const AddProducts = () => {
  const [files, setFiles] = useState([]);
  const [sizeFile, setSizeFile] = useState([]);
  const [categoryValue, setCategoryValue] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [sizes, setSizes] = useState([]);
  const navigate = useNavigate();



  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };


  const formik = useFormik({
    initialValues: {
      name: '',
      shortName: '',
      brandName: '',
      replacementPeriod: '',
      returnPeriod: '',
      isBlocked: 'false',
      isFeatured: 'false',
      isPopular: 'false',
      newArrival: 'false',
      addVariant: [{ price: '', sellingPrice: '', offer: '', stock: '', skuId: '', size: '' }],
    },
    validationSchema: productSchema,
    onSubmit: async () => {
      const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      try {
        const data = new FormData();
        data.append('categoryId', categoryValue);
        data.append('description', editorData);
        Object.entries(values).forEach(([key, value], index) => {
          data.append(key, value);
        });

        data.append('variants', JSON.stringify(values.addVariant));

        files.forEach((item) => {
          data.append('images', item.file);
        });
        sizeFile.forEach((item) => {
          data.append('sizeChartFile', item.file);
        });

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/product`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          toast.success('Product Added');
          navigate('/dashboard/eshop');
          console.log(res.data);
        }
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong. Try again');
      }
    },
  });

  const getSizes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
      console.log(res);
      const temp = res.data.data.sizes;
      setSizes(temp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSizes();
  }, [])

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleAddVariant = () => {
    formik.setValues({
      ...formik.values,
      addVariant: [...formik.values.addVariant, { price: '', sellingPrice: '', offer: '', stock: '', skuId: '' }]
    });
  };

  const handleCloseVariant = (index) => {
    const variants = [...formik.values.addVariant];
    variants.splice(index, 1);
    formik.setValues({
      ...formik.values,
      addVariant: variants
    });
  };

  const discountPercentage = (sellingPrice, mrp) => {
    return Math.round((1 - sellingPrice / mrp) * 100);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Typography variant="h4" paddingBottom={1}>
          Add Product
        </Typography>
        {/* FormikProvider  */}
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px', paddingTop: '15px' }}>
              <Stack direction={'row'} spacing={5} padding={2}>
                <TextField
                  label="Title"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack direction={'row'} spacing={5} padding={2}>
                <TextField
                  label="Short Name"
                  {...getFieldProps('shortName')}
                  error={Boolean(touched.shortName && errors.shortName)}
                  helperText={touched.shortName && errors.shortName}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Brand Name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps('brandName')}
                  error={Boolean(touched.brandName && errors.brandName)}
                  helperText={touched.brandName && errors.brandName}
                />
              </Stack>
              <Stack direction={'row'} spacing={5} padding={2}>
                <TextField
                  label="Replacement Period"
                  {...getFieldProps('replacementPeriod')}
                  error={Boolean(touched.replacementPeriod && errors.replacementPeriod)}
                  helperText={touched.replacementPeriod && errors.replacementPeriod}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Return Period"
                  {...getFieldProps('returnPeriod')}
                  error={Boolean(touched.returnPeriod && errors.returnPeriod)}
                  helperText={touched.returnPeriod && errors.returnPeriod}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>

              <Box padding={2}>
                <Typography variant="h6" paddingBottom={1}>
                  Description
                </Typography>
                <Box style={{ border: '2px solid' }}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Box>
              </Box>
            </Stack>
            <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
              <Box>
                <Typography variant="h6" paddingBottom={1}>
                  Product Pictures
                </Typography>
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple
                  maxFiles={3}
                  credits={false}
                  allowReorder
                  allowImagePreview
                  maxFileSize={'1MB'}
                  labelMaxFileSizeExceeded="	
                  File is too large"
                  labelMaxFileSize="Maximum file size is 1MB"
                  // server="/api"
                  name="files"
                  labelIdle="Drag & Drop your product files"
                />
              </Box>
              <Box>
                <Typography variant="h6" paddingBottom={1}>
                  Size Chart
                </Typography>
                <FilePond
                  files={sizeFile}
                  onupdatefiles={setSizeFile}
                  allowMultiple={false}
                  maxFiles={1}
                  credits={false}
                  maxFileSize={'1MB'}
                  labelMaxFileSizeExceeded="	
                  File is too large"
                  labelMaxFileSize="Maximum file size is 1MB"
                  // server="/api"
                  name="files"
                  labelIdle="Drag & Drop your size chart file"
                />
              </Box>
            </Stack>
            <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
              <Typography variant="h6">Product organization</Typography>
              <Stack direction={'row'} spacing={10} justifyContent="center">
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Category</Typography>
                  {/* Model popup */}
                  <Stack>
                    <CategoryModel setCategoryValue={setCategoryValue} />
                    <FormHelperText style={{ fontSize: '10px', textAlign: 'center' }}>{categoryValue}</FormHelperText>
                  </Stack>

                  {/* Ends Popup  */}
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Featured</Typography>
                  <FormControl>
                    <Select
                      {...getFieldProps('isFeatured')}
                      error={Boolean(touched.isFeatured && errors.isFeatured)}
                      helperText={touched.isFeatured && errors.isFeatured}
                      value="true" id="demo-select-small" autoWidth>
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Status</Typography>
                  <FormControl>
                    <Select value="false" id="demo-select-small" autoWidth
                      {...getFieldProps('isBlocked')}
                      error={Boolean(touched.isBlocked && errors.isBlocked)}
                      helperText={touched.isBlocked && errors.isBlocked}
                    >
                      <MenuItem value="false">Active</MenuItem>
                      <MenuItem value="true">Block</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <Stack direction={'row'} spacing={10} justifyContent="center">
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6" minWidth={100}>
                    New Arrival
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      {...getFieldProps('newArrival')}
                      error={Boolean(touched.newArrival && errors.newArrival)}
                      helperText={touched.newArrival && errors.newArrival}
                      id="demo-simple-select-label"

                    // value={product.isFeatured ? 'true' : 'false'}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Typography variant="h6">Popular</Typography>
                  <FormControl fullWidth>
                    <Select
                      {...getFieldProps('isPopular')}
                      error={Boolean(touched.isPopular && errors.isPopular)}
                      helperText={touched.isPopular && errors.isPopular}
                      id="demo-simple-select-label"
                    // value={product.isFeatured ? 'true' : 'false'}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
              <Typography variant="h6">Variants</Typography>

              {values.addVariant?.map((item, index) => {
                return (
                  <Box key={index} style={{ border: '1px solid', borderRadius: '5px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton aria-label="close" onClick={() => handleCloseVariant(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Stack direction={'row'} spacing={5} padding={2} paddingTop={5}>
                      <TextField
                        label="Price"
                        fullWidth
                        {...formik.getFieldProps(`addVariant[${index}].price`)}
                        error={formik.touched.addVariant?.[index]?.price && Boolean(formik.errors.addVariant?.[index]?.price)}
                        helperText={formik.touched.addVariant?.[index]?.price && formik.errors.addVariant?.[index]?.price}

                        onChange={(e) => {
                          const price = e.target.value;
                          const sellingPrice = item.sellingPrice;
                          const offer = discountPercentage(sellingPrice, price);
                          setFieldValue(`addVariant[${index}].price`, price);
                          setFieldValue(`addVariant[${index}].offer`, `${offer}% off`);
                        }}

                      />

                      <TextField select InputLabelProps={{ shrink: true }} fullWidth
                        {...formik.getFieldProps(`addVariant[${index}].size`)}
                        error={formik.touched.addVariant?.[index]?.size && Boolean(formik.errors.addVariant?.[index]?.size)}
                        helperText={formik.touched.addVariant?.[index]?.size && formik.errors.addVariant?.[index]?.size}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Size"

                      >
                        {sizes?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                    </Stack>
                    <Stack direction={'row'} spacing={5} padding={2}>
                      <TextField
                        label="Selling Price"
                        fullWidth
                        {...formik.getFieldProps(`addVariant[${index}].sellingPrice`)}
                        error={formik.touched.addVariant?.[index]?.sellingPrice && Boolean(formik.errors.addVariant?.[index]?.sellingPrice)}
                        helperText={formik.touched.addVariant?.[index]?.sellingPrice && formik.errors.addVariant?.[index]?.sellingPrice}
                        onChange={(e) => {
                          const sellingPrice = e.target.value;
                          const price = item.price;
                          const offer = discountPercentage(sellingPrice, price);
                          setFieldValue(`addVariant[${index}].sellingPrice`, sellingPrice);
                          setFieldValue(`addVariant[${index}].offer`, `${offer}% off`);
                        }}
                      />
                      <TextField
                        label="SKU ID"
                        fullWidth
                        {...formik.getFieldProps(`addVariant[${index}].skuId`)}
                        error={formik.touched.addVariant?.[index]?.skuId && Boolean(formik.errors.addVariant?.[index]?.skuId)}
                        helperText={formik.touched.addVariant?.[index]?.skuId && formik.errors.addVariant?.[index]?.skuId}
                      />
                    </Stack>
                    <Stack direction={'row'} spacing={5} padding={2}>
                      <TextField
                        type={"text"}
                        label="Offer"
                        disabled
                        value={item.offer}
                        fullWidth
                        {...formik.getFieldProps(`addVariant[${index}].offer`)}
                        error={formik.touched.addVariant?.[index]?.offer && Boolean(formik.errors.addVariant?.[index]?.offer)}
                        helperText={formik.touched.addVariant?.[index]?.offer && formik.errors.addVariant?.[index]?.offer}
                      />
                      <TextField
                        label="Stock"
                        fullWidth
                        {...formik.getFieldProps(`addVariant[${index}].stock`)}
                        error={formik.touched.addVariant?.[index]?.stock && Boolean(formik.errors.addVariant?.[index]?.stock)}
                        helperText={formik.touched.addVariant?.[index]?.stock && formik.errors.addVariant?.[index]?.stock}
                      />
                    </Stack>
                  </Box>
                );
              })}
              <Button onClick={handleAddVariant}>Add Variant</Button>
            </Stack>
            <Stack direction={'row'} justifyContent="center" paddingTop="50px">
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                Add Product
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};

export default AddProducts;
