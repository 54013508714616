// ----------------------------------------------------------------------
const accountData = JSON.parse(localStorage.getItem('profile'));

const account = {
  displayName: `${accountData ? accountData.fullname.toUpperCase() : 'Name'}`,
  // email: 'demo@minimals.cc',
  photoURL: accountData ? accountData.profilePic : " '/static/mock-images/avatars/avatar1.png'",
  // role:` ${accountData ? accountData.accType.toUpperCase() : 'Name'}`,
};

export default account;
