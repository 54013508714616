import {
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const Popup = (props) => {
  console.log(props);
  const status = { type: 'payment', value: props.status };
  const [paymentRemark, setPaymentRemark] = useState('');
  console.log(status);
  const open = true;
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/order/admin/status`, { id: props.item?._id, status, paymentRemark })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        props.handleClose(false);
      });
  };

  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>Are you sure you want to change the payment status of this order?</DialogContent>
        <DialogContentText
          sx={{
            marginX: '20px',
          }}
        >
          {props.status === 'SUCCESS' && (
            <TextField
              id="paymentRemark"
              name="paymentRemark"
              fullWidth
              label="Remark"
              value={paymentRemark}
              onChange={(e) => setPaymentRemark(e.target.value)}
              size="small"
            />
          )}
        </DialogContentText>

        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;
