import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Box, Divider, MenuItem, Stack } from '@mui/material';
import axios from 'axios';

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [categoryStack, setCategoryStack] = useState([]);
  const [parentId, setParentId] = useState();

  const getCategories = async (id) => {
    try {
      const params = id ? { parentId: id } : {};
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/subcategories`, { params });
      if (!res.data.isError) {
        const category = res.data.data.category;
        const temp = categoryStack;
        temp.splice(category.path.split('#').length - 1);
        temp.push(res.data.data);
        setCategoryStack([...categoryStack]);
        console.log('temp', temp);
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const handleMenuClick = (id, value) => {
    console.log(id);
    getCategories(id);
    if (value.isLeaf) {
      onClose(id);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>Select Category</DialogTitle>
      <Stack direction={'row'} spacing={2} padding={2}>
        {categoryStack?.map((item, index) => {
          return (
            <Box sx={{ width: '100%', boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }} key={index}>
              <Typography variant="h5" textAlign={'center'}>
                {item.category?.name}
              </Typography>
              <Divider />
              <Box>
                {item.subCategories?.map((item2, id) => {
                  return (
                    <MenuItem key={id} onClick={() => handleMenuClick(item2._id, item2)}>
                      <ListItemText style={{ textAlign: 'center' }}>{item2?.name}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function CategoryModel({ setCategoryValue }) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
    setCategoryValue(value);
  };

  return (
    <div>
      {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br /> */}
      <Button variant="outlined" onClick={handleClickOpen} color="inherit" size="large">
        Select Category
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
