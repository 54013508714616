import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Box,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Iconify from './Iconify';
import Scrollbar from './Scrollbar';

import DeliveryPopup from './PopupDeliveryStatus';
import { OrderFilter } from './OrderSuccessFilter';
import Popup from './PopupPaymentStatus';

export default function OrderInProgressList() {
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeliveryPopup, setIsDeliveryPopup] = useState(false);
  const [status, setStatus] = useState('');
  const [orderData, setOrderData] = useState({});
  const [isPopup, setIsPopup] = useState(false);

  const [filters, setFilters] = useState({ size: 10, page: 0 });

  const [startDate, setStartDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState('');

  const size = 10;

  const getOrderData = async (filters) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/order/admin/in-progress`, { params: filters });
      console.log('orders', res);
      setOrderData(res.data.data.records);
      setOrderCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeliveryStatusChange = (event, item, index) => {
    setSelectedItem(item);
    setStatus(event.target.value);
    setIsDeliveryPopup(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setIsDeliveryPopup(false);
    setIsPopup(false);
    getOrderData(filters);
    if (message) {
      toast(message);
    }
  };

  const handleStatusChange = (event, item, index) => {
    setSelectedItem(item);
    setStatus(event.target.value);
    setIsPopup(true);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...filters, page: newPage };
    setFilters(temp);
    setPages(newPage);
    getOrderData(temp);
  };

  const applyFilters = (filter) => {
    console.log('bfilter', filter);
    const temp = { size: 10, page: 0 };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.invoiceNumber.length) {
      temp.invoiceNumber = filter.invoiceNumber;
    }
    if (filter.courierId.length) {
      temp.courierId = filter.courierId;
    }
    if (filter.deliveryStatus.length) {
      temp.deliveryStatus = filter.deliveryStatus;
    }
    if (filter.paymentStatus) {
      temp.paymentStatus = filter.paymentStatus;
    }

    if (filter.refundStatus.length) {
      temp.refundStatus = filter.refundStatus;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.isReplacement.length) {
      temp.isReplacement = filter.isReplacement;
    }
    if (filter.paymentType) {
      temp.paymentType = filter.paymentType;
    }
    setFilters(temp);
    setPages(0);
    getOrderData(temp);
  };

  const exportBookings = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/order/orderxlsx`, {
        params: { startDate, endDate, type: 'IN PROGRESS' },
      });
      window.open(res.data.data.url, '_blank');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderData(filters);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} p={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label="From"
                InputLabelProps={{ shrink: true }}
                size="small"
              />
              <TextField
                label="To"
                value={endDate}
                InputLabelProps={{ shrink: true }}
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                size="small"
              />
              <LoadingButton
                loading={loading}
                endIcon={<Iconify icon="bx:export" />}
                variant="contained"
                onClick={exportBookings}
                style={{ maxHeight: '35px' }}
              >
                Export
              </LoadingButton>
            </Stack>
            <OrderFilter applyFilters={applyFilters} />
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small">
                {/* <UserListHead /> */}
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    {/* <TableCell>ID</TableCell> */}
                    <TableCell>Date</TableCell>
                    <TableCell>Invoice Number</TableCell>
                    <TableCell>Payment Type</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Return</TableCell>
                    <TableCell>AWB code</TableCell>
                    <TableCell>Delivery Status</TableCell>
                    <TableCell>Refund Status</TableCell>
                    {/* <TableCell> Updated On </TableCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#fff0ed' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      {/* <TableCell style={{ wordBreak: 'break-all' }}>{item._id}</TableCell> */}
                      <TableCell sx={{ minWidth: '112px' }}>{moment(item.date).format('DD-MM-YYYY')}</TableCell>
                      <TableCell style={{ cursor: 'pointer', width: '100px', wordBreak: 'break-all' }}>
                        <Stack direction="column" spacing={0.5} width="100px">
                          <CopyToClipboard text={item.invoiceNumber} onCopy={() => toast.info('Invoice number copied')}>
                            <Typography>{item.invoiceNumber}</Typography>
                          </CopyToClipboard>
                        </Stack>
                      </TableCell>
                      <TableCell>{item.paymentType === 'COD' ? 'COD' : 'ONLINE'}</TableCell>
                      {item.paymentStatus !== 'SUCCESS' ? (
                        <TableCell>
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select value={item.paymentStatus} onChange={(e) => handleStatusChange(e, item, id)}>
                              <MenuItem value="IN PROGRESS">In Progress</MenuItem>
                              <MenuItem value="SUCCESS">Success</MenuItem>
                              <MenuItem value="FAILED">Failed</MenuItem>
                            </Select>
                            {isPopup && <Popup item={selectedItem} status={status} handleClose={handleClose} />}
                          </FormControl>
                        </TableCell>
                      ) : (
                        <TableCell>{item.paymentStatus}</TableCell>
                      )}
                      <TableCell style={{ cursor: 'pointer', width: '100px' }}>
                        <Stack direction="column" spacing={0.5} width="100px">
                          <Link
                            style={{ textDecoration: 'none', color: 'inherit' }}
                            to={`/dashboard/user/${item.userId}`}
                          >
                            <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                              <Iconify icon="gg:user-list" style={{ height: '18px', width: '18px', color: 'green' }} />
                              <Typography sx={{ color: 'green' }} variant="p">
                                View
                              </Typography>
                            </Stack>
                          </Link>
                          <CopyToClipboard text={item.userId} onCopy={() => toast.info('User id copied')}>
                            <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                              <Iconify
                                icon="lucide:clipboard-copy"
                                style={{ height: '18px', width: '18px', color: '#cb4d4d' }}
                              />
                              <Typography sx={{ color: '#cb4d4d' }} variant="p">
                                Copy ID
                              </Typography>
                            </Stack>
                          </CopyToClipboard>
                        </Stack>
                      </TableCell>
                      <TableCell>{item.isReplacement ? 'REPLACEMENT' : 'NORMAL'}</TableCell>
                      <TableCell>{item.returnStatus || 'NA'}</TableCell>

                      <TableCell style={{ cursor: 'pointer', minWidth: '70px', wordBreak: 'break-all' }}>
                        <Stack direction="column" spacing={0.5} minWidth="70px">
                          <CopyToClipboard text={item.courierId} onCopy={() => toast.info('AWB code copied')}>
                            <Typography>{item.courierId}</Typography>
                          </CopyToClipboard>
                        </Stack>
                      </TableCell>

                      <TableCell>
                        <FormControl>
                          <Select
                            value={item.deliveryStatus}
                            size="small"
                            sx={{ wordBreak: 'break-word' }}
                            onChange={(e) => handleDeliveryStatusChange(e, item, id)}
                          >
                            <MenuItem value="PENDING">Pending</MenuItem>
                            <MenuItem value="PACKAGE IN PROGRESS">Package In Progress</MenuItem>
                            <MenuItem value="SHIPPED">Shipped</MenuItem>
                            <MenuItem value="DELIVERED">Delivered</MenuItem>
                            <MenuItem value="CANCELLED">Cancelled</MenuItem>
                          </Select>
                          {isDeliveryPopup && (
                            <DeliveryPopup item={selectedItem} status={status} handleClose={handleClose} />
                          )}
                        </FormControl>
                      </TableCell>

                      <TableCell>{item.refundStatus}</TableCell>
                      {/* <TableCell>{moment(item.updatedAt).format('MMMM DD, YYYY')}</TableCell> */}
                      <TableCell>
                        {item.isReplacement ? (
                          <Box>
                            <Link to={`/dashboard/order-replace/${item.referenceOrder}`}>
                              <Icon sx={{ color: 'gray' }}>
                                <RemoveRedEyeOutlinedIcon />
                              </Icon>
                            </Link>
                          </Box>
                        ) : (
                          <Box>
                            <Link to={`/dashboard/order/${item._id}`}>
                              <Icon sx={{ color: 'gray' }}>
                                <RemoveRedEyeOutlinedIcon />
                              </Icon>
                            </Link>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[]}
            component={'div'}
            showFirstButton
            showLastButton
            count={orderCount}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            page={pages}
          />
        </Card>
      )}
    </div>
  );
}
