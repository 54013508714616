import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const AddCategory = ({ parentId, categoryName, getCategories, open, handleClose }) => {
  const categorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      isLeaf: false,
      isPopular: false,
    },
    validationSchema: categorySchema,
    onSubmit: async () => {
      try {
        const res = await axios
          .post(
            `${process.env.REACT_APP_API_URL}/category`,
            { ...values, parentId: `${parentId}` },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then((res) => {
            if (!res.data.isError) {
              toast.success('Category Added');
              // console.log(res.data);
              handleClose();
              getCategories();
            }
          })
          .catch((error) => {
            toast.error('Something went wrong. Try again');
          });
      } catch (error) {
        console.log(error);
      }
    },
  });
  const closeDialog = () => {
    formik.resetForm();
    handleClose();
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <Dialog open={open} onClose={closeDialog}
    fullScreen={matches}
    >
      <Stack
        sx={{
          paddingTop: '25px',
          paddingBottom: '30px',
          paddingX: { xs: '10px', sm: '20px', md: '20px', lg: '20px', xl: '20px' },
          width: { xs: '100%', sm: '600px', md: '600px' },
        }}
        spacing={4}
      >
        <Stack
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '-20px',
              right: '-10px',
              zIndex: 5,
              color: 'grey.500',
              '&:hover': {
                color: 'grey.700',
              },
            }}
            onClick={closeDialog}
          >
            <Close />
          </IconButton>
          <Stack
            sx={{
              marginTop: '-10px',
            }}
          >
            <Typography variant="h4" textAlign={'center'}>
              ADD SUB CATEGORY
            </Typography>
            <Divider />
          </Stack>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  label="Parent Category"
                  value={categoryName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                  <Typography sx={{ width: '30%' }}>Is Leaf:</Typography>
                  <FormControl fullWidth>
                    <Select
                      value="true"
                      id="demo-select-small"
                      fullWidth
                      {...getFieldProps('isLeaf')}
                      error={Boolean(touched.isLeaf && errors.isLeaf)}
                      helperText={touched.isLeaf && errors.isLeaf}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                  <Typography sx={{ width: '50%' }}>Is Popular:</Typography>
                  <FormControl fullWidth>
                    <Select
                      value="true"
                      id="demo-select-small"
                      fullWidth
                      {...getFieldProps('isPopular')}
                      error={Boolean(touched.isPopular && errors.isPopular)}
                      helperText={touched.isPopular && errors.isPopular}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Add Category
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
    </Dialog>
  );
};

export default AddCategory;
