import React from 'react';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, Menu, MenuItem } from '@mui/material';
import Iconify from './Iconify';

export const ProductsFilter = ({ applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box style={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton onClick={handleClick}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Formik
            initialValues={{
              name: '',
              categoryName: '',
              skuId: '',
              brandName: '',
              isBlocked: '',
              startDate: '',
              endDate: '',
              stock: '',
            }}
            onSubmit={async (values) => {
              applyFilters(values);
              handleClose();
            }}
          >
            {({ values, handleChange }) => (
              <Form style={{ padding: '10px' }}>
                <Stack direction="column">
                  <Stack direction="row" spacing={2}>
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="name"
                      name="name"
                      fullWidth
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="brandName"
                      name="brandName"
                      label="Brand Name"
                      value={values.brandName}
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="skuId"
                      name="skuId"
                      fullWidth
                      label="SKU ID"
                      value={values.skuId}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      id="categoryName"
                      name="categoryName"
                      label="Category Name"
                      fullWidth
                      value={values.categoryName}
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} style={{ paddingBottom: '10px' }}>
                    <TextField
                      select
                      label="Status"
                      fullWidth
                      name="isBlocked"
                      value={values.isBlocked}
                      onChange={handleChange}
                    >
                      <MenuItem value="true">Blocked</MenuItem>
                      <MenuItem value="false">Active</MenuItem>
                    </TextField>

                    <TextField
                      select
                      label="Stock"
                      fullWidth
                      name="stock"
                      value={values.stock}
                      onChange={handleChange}
                    >
                      <MenuItem value="IN_STOCK">IN STOCK</MenuItem>
                      <MenuItem value="OUT_OF_STOCK">OUT OF STOCK</MenuItem>
                      <MenuItem value="LOW_STOCK">LOW STOCK</MenuItem>
                    </TextField>
                  </Stack>

                  <Stack direction={'row'} spacing={2}>
                    <TextField
                      fullWidth
                      type="date"
                      name="startDate"
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={values.startDate}
                      onChange={handleChange}
                    />

                    <p style={{ paddingTop: '15px' }}>to</p>

                    <TextField
                      fullWidth
                      type="date"
                      name="endDate"
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      value={values.endDate}
                      onChange={handleChange}
                    />
                  </Stack>

                  <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                    Filter
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Menu>
      </Box>
    </div>
  );
};
