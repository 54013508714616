// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'e-shop',
    path: '/dashboard/eshop',
    icon: getIcon('eva:shopping-bag-fill'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('fa-solid:list-alt'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'returns',
    path: '/dashboard/returns',
    icon: getIcon('material-symbols:assignment-return'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'refund ',
    path: '/dashboard/refunds',
    icon: getIcon('mdi:credit-card-refund'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
   title:"settings",
    path:"/dashboard/settings",
    icon:getIcon('eva:settings-2-fill'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'Admins',
    path: '/dashboard/account',
    icon: getIcon("eos-icons:admin"),
    permission: ["SUPER_ADMIN"],
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
