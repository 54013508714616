import { Box, Button, CircularProgress, Divider, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import AddCategory from './AddCategory';
import CategoryAddForm from './CategoryForm';

const Categories = () => {
  // const [category, setCategory] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuClicked, setIsMenuClicked] = useState(0);
  // const [subCategory, setSubCategory] = useState();
  const [categoryStack, setCategoryStack] = useState([]);
  const [parentId, setParentId] = useState();
  const [categoryName, setCategoryName] = useState();

  const getCategories = async (id) => {
    try {
      const params = id ? { parentId: id } : {};
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/subcategories`, { params });
      if (!res.data.isError) {
        const category = res.data.data.category;
        const temp = categoryStack;
        temp.splice(category.path.split('#').length - 1);
        temp.push(res.data.data);
        setCategoryStack([...categoryStack]);
        console.log('temp', temp);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  // const handleMenuClick = async (id) => {
  //   setIsMenuClicked(isMenuClicked + 1);
  //   try {
  //     const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/subcategories`, {
  //       params: { parentId: id },
  //     });
  //     console.log('res', res);
  //     setSubCategory(res.data.data.category);
  //     //   setCategory(res.data.data.category);
  //     //   setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleAddButtonClick = (id) => {
  //   setParentId(id);
  // };

  // const handleAddButtonClickOfSub = (id) => {
  //   setParentId(id);
  // };

  const handleMenuClick = (id) => {
    console.log(id);
    getCategories(id);
  };
  const handleAddButtonClick = (id, name) => {
    handleClickOpen()
    setParentId(id);
    setCategoryName(name);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Stack direction={'row'} sx={{ flexWrap: 'wrap' }} spacing={2}>
            {categoryStack?.map((item, index) => (
                <Box sx={{ boxShadow: '0 0 2px 1px #000', borderRadius: '5px', width: '250px' }} key={index}>
                  <Typography variant="h5" textAlign={'center'}>
                    {item.category?.name}
                  </Typography>
                  <Divider />
                  <Box>
                    {item.subCategories?.map((item2, id) => (
                        <MenuItem key={id} onClick={() => handleMenuClick(item2._id)} disabled={item2.isLeaf}>
                          <ListItemText style={{ textAlign: 'center' }}>{item2?.name}</ListItemText>
                        </MenuItem>
                      ))}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', aliginItems: 'center', padding: '10px' }}>
                    <Button
                      variant="contained"
                      onClick={() => handleAddButtonClick(item.category._id, item.category.name)}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              ))}
          </Stack>
          {parentId && categoryName && (
            <AddCategory
              parentId={parentId}
              categoryName={categoryName}
              getCategories={getCategories}
              open={open}
              handleClose={handleClose}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Categories;
